import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatDrawer, MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { ProcessAppointmentComponent } from "./components/process-appointment/process-appointment.component";
import { PushFormToAppComponent } from "./components/push-form-to-app/push-form-to-app.component";
import { ContactInfoComponent } from "./components/contact-info/contact-info.component";
import { PaymentInfoComponent } from "./components/payment-info/payment-info.component";
import { PatientNotesComponent } from "./components/patient-notes/patient-notes.component";
import { AlertsInterface, AthenaAlertsInterface, PatientNotesInterface, VisitNotesInterface } from '../../registration/registration.interface';
import { BroadcastComponent } from '../broadcast/broadcast.component';
import { OverlayContainer } from '@angular/cdk/overlay';
import { AlertsComponent, Alert } from "./components/alerts/alerts.component";
import { MatTooltipModule } from '@angular/material/tooltip';
import { TableActionsDrawerService } from './table-actions-drawer.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { PatientInfoComponent } from "./components/patient-info/patient-info.component";

export type ManageDrawerIntrface = {
  name: string;
  chartNumber: string;
  provider: string;
  apptType: string;
  apptTime: string;
  contactDetails: {
    email: string;
    phone: string;
  },
  meetingId: number;
  patientId: number;
  athenaNotes: string[];
  patientNotes: PatientNotesInterface;
  visitNotes: VisitNotesInterface;
  payment_details: any;
  alerts?: AlertsInterface
}

@Component({
  selector: 'app-table-actions-drawer',
  standalone: true,
  imports: [CommonModule, MatSidenavModule, MatSlideToggleModule, MatIconModule, DragDropModule, MatTableModule, ProcessAppointmentComponent, PushFormToAppComponent, ContactInfoComponent, PaymentInfoComponent, PatientNotesComponent, BroadcastComponent, AlertsComponent, MatTooltipModule, MatProgressSpinnerModule, PatientInfoComponent],
  templateUrl: './table-actions-drawer.component.html',
  styleUrl: './table-actions-drawer.component.css'
})
export class TableActionsDrawerComponent implements OnChanges {
  @Input({ required: true }) rowData!: ManageDrawerIntrface[] // Input to accept row data or multple appointment rows
  @Input({ required: true }) action!: string // Input to accept action
  @Input({ required: true }) tableType!: string // Input to tabletype
  @Input({ required: true }) rawRow!: any // Input to tabletype
  @Input({ required: true }) missedSections!: { form_name: string; form_id: number }[] // Input to accept missed sections
  @Input() selectedRecords: any[] = []; // selected records input
  @Input() title!: { id: string; name: string }; // title of broadcast
  @Input() timezone_name!: string; // timezone name
  @Input({ required: true }) isFuturePastDate!: boolean; // future or past date flag
  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild('drawerContent', { static: false }) drawerContent!: ElementRef;
  @Output() cancelAppt = new EventEmitter<number>()
  @Output() triggerRefresh = new EventEmitter<boolean>()
  @Output() markRowEvent = new EventEmitter<{row: ManageDrawerIntrface; mark: boolean; tableType: string}>()


  dataSource = new MatTableDataSource<any>();  // Data source for the table

  displayedColumns: string[] = ['name', 'chartNumber', 'provider', 'apptType', 'apptTime'];
  name!: string;
  largewidthDrawerMap: { [key: string]: string } = {
    'healent-action-icon': 'drawer-medium',
    'healent-action-icon-1': 'drawer-medium',
    'healent-path-3627': 'drawer-medium',
    'broadcast': 'drawer-medium',
    'healent-path-3625': 'drawer-small',
    'healent-path-3626': 'drawer-medium',
    'patientInfo': 'drawer-large'
  };
  rowObject!: {
    name: string; chartNumber: string; provider: string; apptType: string; apptTime: string; contactDetails: {
      email: string;
      phone: string;
    }; meetingId: number; patientId: number; athenaNotes: string[]; patientNotes: PatientNotesInterface; visitNotes: VisitNotesInterface; payment_details: any; alerts?: AlertsInterface
  };
  combinedAthenaAlerts: any[] = [];
  loading: boolean = false;
  formDetails: any;

  constructor(private elementRef: ElementRef, private overlayContainer: OverlayContainer, private tableActionsDrawerService: TableActionsDrawerService) {}

  ngOnChanges(changes: SimpleChanges) {
    console.log('i am here======',  this.action !== 'broadcasr')
    console.log('i am here===ddsdsd===',  this.action)
    if (changes['rowData']) {
      // When rowData changes, update the table's data source
      this.dataSource = new MatTableDataSource(this.rowData);
      this.name = this.rowData?.length > 0 ? this.rowData[0]?.name : '';

      this.combinedAthenaAlerts = (this.rowData || []).reduce((acc: { meetingId: number; errorDetails: string; time: string; type: string; ignored: boolean; alert_id: string }[], row) => {
        if (row.alerts && row.alerts.athena_alerts && Array.isArray(row.alerts.athena_alerts)) {
          const updatedAlerts = row.alerts.athena_alerts.map(alert => ({
            ...alert,
            meetingId: row.meetingId // Add meetingId to each alert
          }));
          return [...acc, ...updatedAlerts];
        }
        return acc;
      }, []);

      this.rowObject = this.rowData?.length > 0 && typeof this.rowData[0] === 'object'
        ? this.rowData[0]
        : {
          name: '',
          chartNumber: '',
          provider: '',
          apptType: '',
          apptTime: '',
          contactDetails: { email: '', phone: '' },
          meetingId: 0,
          patientId: 0,
          athenaNotes: [],
          patientNotes: {} as PatientNotesInterface,
          visitNotes: {} as VisitNotesInterface,
          payment_details: {} as any,
          alerts: {} as AlertsInterface
        };

      if (this.action === 'healent-action-icon') {
        this.loading = true;
        this.tableActionsDrawerService.postformDetails('dashboard/form/details', {
          patient_id: this.rowData[0].patientId,
          timezone_name: this.timezone_name,
          meeting_id: this.rowData[0].meetingId
        }).subscribe((res) => {
          if (res?.message) {
            this.formDetails = res.message;
            this.loading = false
          }
        }, (error) => {
          this.loading = false
        });
      }
    }
  }

  getDrawerClass(action: string): string {
    return this.largewidthDrawerMap[action] || 'drawer-medium'; // Fallback class
  }

  getBackgroundColorClass(action: string): string {
    return action === 'patientInfo' ? 'patient-background-class' : 'actions-background-class'
  }

  getPaddingClass(action: string): string {
    if (action === 'patientInfo' || action === 'broadcast') {
      return 'padding-class-not-applied'
    }
    return 'padding-class-applied'
  }

  @HostListener('document:click', ['$event'])
  onClickOutside(event: MouseEvent) {
    const clickedInside =
      this.elementRef.nativeElement.contains(event.target) ||
      this.isClickOnOverlay(event.target as HTMLElement);
    if (!clickedInside && this.drawer.opened) {
      this.drawer.close();
    }
  }

  private isClickOnOverlay(target: HTMLElement): boolean {
    const overlayElement = this.overlayContainer.getContainerElement();
    return overlayElement.contains(target);
  }

  getDrawerTitle(action: string, name: string): string {
    switch (action) {
      case 'healent-action-icon':
        return `Appointment - ${name}`;
      case 'healent-path-3625':
        return `Contact Info - ${name}`;
      case 'healent-action-icon-1':
        return `Payment Details - ${name}`;
      case 'healent-path-3627':
        return `Notes - ${name}`;
      case 'broadcast':
        return `Broadcast Message - ${name}`;
      default:
        return `Manage - ${name}`;
    }
  }

  presetScrollPosition(): void {
    // Reset scroll position
    if (this.drawerContent) {
      this.drawerContent.nativeElement.scrollTop = 0;
    }
  }

  closeDrawer() {
    if (this.drawer) {
      this.drawer.close();
      this.loading = false
    }
  }

  openDrawer() {
    if (this.drawer) {
      this.presetScrollPosition();
      this.drawer.open();
    }
  }

  onCancelAppointment (meetingId: number, event: MouseEvent) {
    event.stopPropagation();
    this.cancelAppt.emit(meetingId)
  }

  triggerDataRefresh (trigger: any) {
    this.triggerRefresh.emit(trigger)
  }

  markRow(mark: boolean) {
    this.markRowEvent.emit({
      row: this.rowData[0],
      mark: mark,
      tableType: this.tableType
    }
    )
  }

  onAlertStatusChanged(updatedAlerts: Alert[]) {
    // Update the alerts in rowData to maintain sync
    if (this.rowData && this.rowData.length > 0) {
      this.rowData = this.rowData.map((row): ManageDrawerIntrface => {
        if (row.alerts && row.alerts.athena_alerts) {
          const updatedAthenaAlerts = row.alerts.athena_alerts.map(alert => {
            const updatedAlert = updatedAlerts.find(a => a.alert_id === alert.alert_id);
            // Ensure compatibility with AthenaAlertsInterface
            if (updatedAlert) {
              return {
                ...alert,
                errorDetails: updatedAlert.errorDetails || '', // Provide a default string
                time: updatedAlert.time || alert.time, // Use existing time if missing
                type: updatedAlert.type || alert.type, // Use existing type if missing
              } as AthenaAlertsInterface;
            }
            return alert; // Return the original alert if no update is found
          });

          return {
            ...row,
            alerts: {
              ...row.alerts,
              athena_alerts: updatedAthenaAlerts as AthenaAlertsInterface[], // Explicitly cast
            },
          };
        }
        return row;
      });

      // Trigger a refresh if needed
      this.triggerDataRefresh(true);
    }
  }

}
