<div *ngIf="patientData" class="patient-info-container">
  <div class="patient-header">
    <div class="info-header">Patient Info</div>
    <div class="actions">
      <button class="icon-button">
        <i [ngClass]="'healent-document'"></i>
      </button>
      <button class="icon-button">
        <i [ngClass]="'healent-edit'"></i>
      </button>
    </div>
  </div>

  <div class="patient-profile">
    <img [src]="'assets/profile-placeholder.jpg'" alt="Patient photo" class="profile-image">
    <div class="patient-name">
      <h3>{{ patientData.client_name || '-' }}</h3>
      <p class="last-update">Last update: {{ patientData.deactivated_at || '-' }}</p>
    </div>
  </div>

  <div class="info-grid">
    <div>
      <div class="info-item">
        <label>Date Of Birth</label>
        <p>{{ patientData.date_of_birth || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Phone No</label>
        <p>{{ patientData.phone || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Email</label>
        <p>{{ patientData.email || '-' }}</p>
      </div>
    </div>
    <div>
      <div class="info-item">
        <label>BMI</label>
        <p>{{ patientData.bmi || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Weight</label>
        <p>{{ patientData.weight || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Height</label>
        <p>{{ patientData.height || '-' }}</p>
      </div>
    </div>
    <div>
      <div class="info-item">
        <label>Provider</label>
        <p>{{ patientData.bmi || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Confirmed At</label>
        <p>{{ patientData.weight || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Last In App Activity</label>
        <p>{{ patientData.height || '-' }}</p>
      </div>
    </div>
    <div>
      <div class="info-item">
        <label>A1C</label>
        <p>{{ patientData.height || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Medical Record Number</label>
        <p>{{ patientData.height || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Date Seen For Optimization</label>
        <p>{{ patientData.plan_start_date || '-' }}</p>
      </div>
    </div>
    <div>
      <div class="info-item">
        <label>Surgery Date</label>
        <p>{{ patientData.height || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Care Protocol</label>
        <p>{{ patientData.height || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Date For Return Visit</label>
        <p>{{ patientData.plan_end_date || '-' }}</p>
      </div>
    </div>
    <div>
      <div class="info-item">
        <label>First Appointment At</label>
        <p>{{ patientData.height || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Last Appointment At</label>
        <p>{{ patientData.plan_end_date || '-' }}</p>
      </div>
      <div class="info-item">
        <label>Total Appointments</label>
        <p>{{ patientData.pending_activation || '-' }}</p>
      </div>
    </div>
  </div>

  <div class="additional-info">
    <div class="info-item info-item-bottom-border">
      <label>Insurance Info</label>
      <p>{{ patientData.insuranceInfo || '-' }}</p>
    </div>
    <div class="info-item info-item-bottom-border">
      <label>Diagnosis</label>
      <p>{{ patientData.diagnosis || '-' }}</p>
    </div>
    <div class="info-item">
      <label>Outstanding Issues Or Concerns</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item">
      <label>What Is Needed For "Clearance - Cards, Id, Vascular, Pulmonary"</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item">
      <label>Outstanding issues to follow-up on</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item">
      <label>Where they stand</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
    <div class="info-item">
      <label>Repeat Check In</label>
      <p>{{ patientData.outstandingIssues || '-' }}</p>
    </div>
  </div>
</div>
