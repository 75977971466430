<div class="process-appointment-container" *ngIf="isFuturePastDate">
  <div class="process-appointment-header">
    <i [ngClass]="'healent-path-3624'"></i>
    <h2>
      Process Appointment
    </h2>
  </div>
  <div class="appointment-buttons">
    <div class="action-card manual-check-in" (mouseover)="hoverCheckIn = true" (mouseleave)="hoverCheckIn = false" (click)="onCheckIn($event)">
      <button class="manual-check-in-button" [ngClass]="{'hovered': hoverCheckIn}" >
        <i [ngClass]="'healent-path-3633'"></i> Manual check in
      </button>
    </div>
  </div>
</div>
