import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TableActionsDrawerService } from '../../table-actions-drawer.service';

@Component({
  selector: 'app-process-appointment',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './process-appointment.component.html',
  styleUrls: ['./process-appointment.component.css']
})
export class ProcessAppointmentComponent {
  hoverCheckIn: boolean = false;
  hoverManualCheckIn: boolean = false;

  @Input({ required: true }) rawRow!: any;
  @Input({ required: true }) isFuturePastDate!: boolean;
  @Input({ required: true }) tableType!: string;

  constructor(private tableActionsDrawerService: TableActionsDrawerService) {}

  onCheckIn(event: MouseEvent) {
    event.stopPropagation()
    // Trigger an action with the necessary data (e.g., meetingId)
    this.tableActionsDrawerService.triggerAction({ row: this.rawRow, source_status: this.tableType, target_status: 'checked-in' });
  }
}
