import { TableActionsDrawerService } from './../../resusable/table-actions-drawer/table-actions-drawer.service';
import { AfterContentChecked, Renderer2, ChangeDetectorRef, Component, OnInit, QueryList, viewChild, ViewChild, ViewChildren, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { environment } from "../../../environments/environment";
import { RegistrationService } from "../registration.service";
import {
  AlertsInterface,
  AthenaAlertsInterface,
  HospitalLocationInterface,
  HospitalLocationPostInterfaceData,
  HospitalLocationUserInterface, StatusPutInterfaceData
} from "../registration.interface";
import { MatError, MatFormField, MatFormFieldModule, MatLabel } from "@angular/material/form-field";
import { MatOption } from "@angular/material/autocomplete";
import { MatSelect } from "@angular/material/select";
import { DatePipe, NgClass, NgForOf, NgIf, CommonModule, CurrencyPipe } from "@angular/common";
import { MatProgressBar } from "@angular/material/progress-bar";
import {
  MatCell,
  MatCellDef,
  MatColumnDef,
  MatHeaderCell, MatHeaderCellDef,
  MatHeaderRow, MatHeaderRowDef, MatRow, MatRowDef, MatTable,
  MatTableDataSource
} from "@angular/material/table";

import { MatSort, MatSortHeader } from "@angular/material/sort";
import { MatCard } from "@angular/material/card";
import { MatIcon, MatIconModule } from "@angular/material/icon";
import { MatButton, MatButtonModule, MatIconButton } from "@angular/material/button";
import { MatInput, MatInputModule } from "@angular/material/input";
import { MatDivider } from "@angular/material/divider";
import { MatList, MatListItem } from "@angular/material/list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
  MatAccordion, MatExpansionModule,
  MatExpansionPanel,
  MatExpansionPanelDescription,
  MatExpansionPanelTitle
} from "@angular/material/expansion";
import { MatBadge, MatBadgeModule } from "@angular/material/badge";
import { MatTooltip } from "@angular/material/tooltip";
import {
  MatCalendarCellCssClasses,
  MatDatepicker,
  MatDatepickerInput,
  MatDatepickerModule,
  MatDatepickerToggle
} from "@angular/material/datepicker";
import { provideNativeDateAdapter } from "@angular/material/core";
import { MatCheckbox, MatCheckboxModule } from "@angular/material/checkbox";
import { SelectionModel } from "@angular/cdk/collections";
import { MatMenu, MatMenuItem, MatMenuTrigger } from "@angular/material/menu";
import { MatSortModule } from '@angular/material/sort';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDrawer } from '@angular/material/sidenav';
import { MatSidenavModule } from '@angular/material/sidenav';


import { CustomDatepickerComponent } from '../../resusable/custom-datepicker/custom-datepicker.component';
import { CustomLocationSelectComponent } from '../../resusable/custom-location-select/custom-location-select.component';
import { TableSettingsDrawerComponent } from '../../resusable/table-settings-drawer/table-settings-drawer.component';
import { TableActionsDrawerComponent } from "../../resusable/table-actions-drawer/table-actions-drawer.component";
import { BehaviorSubject, distinctUntilChanged, filter, interval, map, startWith, Subject, Subscription, switchMap, takeUntil } from 'rxjs';
import { FiltersComponentComponent } from '../../resusable/filter-bar/filters-bar.component';

export interface MoveSelectInterface {
  readonly value: string;
  readonly viewValue: string;
}

export interface HospitalLocationUserTableInterfaceData {
  client_name: string,
  date_of_birth: string,
}

@Component({
  selector: 'app-registration-dashboard',
  standalone: true,
  providers: [DatePipe, provideNativeDateAdapter(), CurrencyPipe],
  imports: [
    CommonModule,
    MatSidenavModule,
    MatFormFieldModule, MatInputModule, MatDatepickerModule,
    MatExpansionModule,
    MatBadgeModule, MatButtonModule, MatIconModule,
    MatError,
    MatFormField,
    MatLabel,
    MatOption,
    MatSelect,
    NgForOf,
    MatProgressBar,
    NgIf,
    MatCard,
    MatCell,
    MatCellDef,
    MatColumnDef,
    MatHeaderCell,
    MatHeaderRow,
    MatHeaderRowDef,
    MatIcon,
    MatIconButton,
    MatInput,
    MatRow,
    MatRowDef,
    MatSort,
    MatSortHeader,
    MatTable,
    NgClass,
    MatHeaderCellDef,
    MatDivider,
    MatList,
    MatListItem,
    FormsModule,
    ReactiveFormsModule,
    MatAccordion,
    MatExpansionPanel,
    MatExpansionPanelTitle,
    MatExpansionPanelDescription,
    MatButton,
    MatBadge, DatePipe, MatTooltip, MatDatepickerInput, MatDatepickerToggle, MatDatepicker, MatCheckbox, MatCheckboxModule, MatMenuTrigger, MatMenu, MatMenuItem,
    MatSortModule,
    DragDropModule,
    CustomDatepickerComponent,
    CustomLocationSelectComponent,
    TableSettingsDrawerComponent,
    TableActionsDrawerComponent,
    FiltersComponentComponent
],
  //changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './registration-dashboard.component.html',
  styleUrl: './registration-dashboard.component.css'
})
export class RegistrationDashboardComponent implements OnInit, AfterViewInit, AfterContentChecked {
  move_select_PreRegistered_list: MoveSelectInterface[] = [
    { value: 'in-progress', viewValue: 'in-progress' },
    { value: 'checked-in', viewValue: 'checked-in' },
  ];

  move_select_InProgress_list: MoveSelectInterface[] = [
    { value: 'pre-registered', viewValue: 'pre-registered' },
    { value: 'checked-in', viewValue: 'checked-in' }
  ];

  move_select_CheckedIn_list: MoveSelectInterface[] = [
    { value: 'pre-registered', viewValue: 'pre-registered' },
    { value: 'in-progress', viewValue: 'in-progress' },
  ];

  searchText: string = '';
  location_id: number = -1;
  currentDate: Date | undefined;
  isAccordionOpen = true;
  is_loading: boolean = false
  is_loading_table: boolean = true
  locations: HospitalLocationInterface[] = []
  user_data: HospitalLocationUserInterface[] = []
  hospital_id: number = 0
  timezone_name: string = ''
  selectedLocation: number = -1;
  iconState: { [key: string]: string } = {};
  tooltipState: { [key: string]: string } = {};
  pre_registered_data: HospitalLocationUserInterface[] = []
  checked_id_data: HospitalLocationUserInterface[] = []
  registration_in_progress_data: HospitalLocationUserInterface[] = []

  dataSourcePreRegistered = new MatTableDataSource<HospitalLocationUserInterface>([]);
  dataSourceInProgress = new MatTableDataSource<HospitalLocationUserInterface>([]);
  dataSourceCheckedIn = new MatTableDataSource<HospitalLocationUserInterface>([]);
  allColumnsPreRegistered = [
    { name: 'select', label: 'Select', visible: true },
    { name: 'client_active', label: '', visible: true },
    { name: 'client_name', label: 'Patient Name', visible: true },
    { name: 'alerts', label: 'Alerts', visible: true },
    { name: 'date_of_birth', label: 'DOB', visible: true },
    { name: 'chart_number', label: 'Chart #', visible: true },
    { name: 'surgeon_name', label: 'Provider', visible: true },
    { name: 'appointment_type', label: 'Appointment Type', visible: true },
    { name: 'appointment_unixts', label: 'Appointment Time', visible: true },
    { name: 'insurance_name', label: 'Insurance', visible: true },
    // { name: 'status', label: 'Reg status', visible: true },
    { name: 'Delete', label: 'Actions', visible: true },
    { name: 'copay', label: 'Copay', visible: true },
    //{ name: 'paid', label: 'Paid', visible: true },
    { name: 'balance', label: 'Balance', visible: true }
  ];

  allColumnsInProgress = [
    { name: 'select', label: 'Select', visible: true },
    { name: 'client_active', label: '', visible: true },
    { name: 'client_name', label: 'Patient Name', visible: true },
    { name: 'alerts', label: 'Alerts', visible: true },
    { name: 'date_of_birth', label: 'DOB', visible: true },
    { name: 'chart_number', label: 'Chart #', visible: true },
    { name: 'surgeon_name', label: 'Provider', visible: true },
    { name: 'appointment_type', label: 'Appointment Type', visible: true },
    { name: 'appointment_unixts', label: 'Appointment Time', visible: true },
    { name: 'insurance_name', label: 'Insurance', visible: true },
    // { name: 'status', label: 'Reg status', visible: true },
    { name: 'Delete', label: 'Action', visible: true },
    { name: 'copay', label: 'Copay', visible: true },
    //{ name: 'paid', label: 'Paid', visible: true },
    { name: 'balance', label: 'Balance', visible: true }
  ];

  allColumnsCheckedIn = [
    { name: 'select', label: 'Select', visible: true },
    { name: 'client_active', label: '', visible: true },
    { name: 'client_name', label: 'Patient Name', visible: true },
    { name: 'alerts', label: 'Alerts', visible: true },
    { name: 'date_of_birth', label: 'DOB', visible: true },
    { name: 'chart_number', label: 'Chart #', visible: true },
    { name: 'surgeon_name', label: 'Provider', visible: true },
    { name: 'appointment_type', label: 'Appointment Type', visible: true },
    { name: 'appointment_unixts', label: 'Appointment Time', visible: true },
    { name: 'insurance_name', label: 'Insurance', visible: true },
    // { name: 'status', label: 'Reg status', visible: true },
    { name: 'Delete', label: 'Action', visible: true },
    { name: 'copay', label: 'Copay', visible: true },
    //{ name: 'paid', label: 'Paid', visible: true },
    { name: 'balance', label: 'Balance', visible: true }
  ];

  displayedColumnsPreRegistered: string[] = this.allColumnsPreRegistered.filter(col => col.visible).map(col => col.name);
  displayedColumnsInProgress: string[] = this.allColumnsInProgress.filter(col => col.visible).map(col => col.name);
  displayedColumnsCheckedIn: string[] = this.allColumnsCheckedIn.filter(col => col.visible).map(col => col.name);


  @ViewChild('drawer') drawer!: MatDrawer;
  @ViewChild('input') inputElement!: ElementRef;

  @ViewChildren(MatSort) sort = new QueryList<MatSort>();
  @ViewChild('tableSettingsDrawerPreRegistered') tableSettingsDrawerPreRegistered!: TableSettingsDrawerComponent;
  @ViewChild('tableSettingsDrawerInProgress') tableSettingsDrawerInProgress!: TableSettingsDrawerComponent;
  @ViewChild('tableSettingsDrawerCheckedIn') tableSettingsDrawerCheckedIn!: TableSettingsDrawerComponent;
  @ViewChild('actionsDrawer') actionsDrawer!: TableActionsDrawerComponent;

  accordion = viewChild.required(MatAccordion);
  checkboxStates: { [key: string]: boolean } = {};
  panelExpanded: { [key: string]: boolean } = {
    'pre-registered': true,
    'in-progress': true,
    'checked-in': true
  };
  private resizeObserver: ResizeObserver | undefined;
  selectedRow: any;
  selectedAction: string = '';
  private intervalId: any;
  refreshFunctionTriggered: boolean = false;
  tableType!: string;
  missed_sections!: { form_name: string; form_id: number }[];
  enableProgressBar: boolean = false;
  isPreRegisteredEnabled: boolean = true;
  isInProgressEnabled: boolean = true;
  isCheckedInEnabled: boolean = true;
  rawRow!: HospitalLocationUserInterface;
  private subscription!: Subscription;
  isFromProcessAppt!: boolean;
  currentRowData!: any[];
  private destroy$ = new Subject<void>();
  private refresh$ = new BehaviorSubject<{date: Date | undefined, locationId: number}>({
    date: undefined,
    locationId: -1
  });
  filterButtons = []
  fiterBarFilters: string = '';
  private sortStatePreRegistered: { active: string, direction: 'asc' | 'desc' } = { active: 'appointment_unixts', direction: 'asc' };
  private sortStateInProgress: { active: string, direction: 'asc' | 'desc' } = { active: 'appointment_unixts', direction: 'asc' };
  private sortStateCheckedIn: { active: string, direction: 'asc' | 'desc' } = { active: 'appointment_unixts', direction: 'desc' };
  selectedRecords: HospitalLocationUserInterface[] = [];
  title!: { id: string; name: string };
  randomColorMap = new Map<number, string>();
  userData$: BehaviorSubject<HospitalLocationUserInterface[]> = new BehaviorSubject<HospitalLocationUserInterface[]>([]);
  showActionButtons: boolean = true;
  panelTooltips: { [key: string]: string } = {
    'pre-registered': 'Maximize',
    'in-progress': 'Maximize',
    'checked-in': 'Maximize'
  };
  selectedTimezone!: string;

  constructor(
    private registrationService: RegistrationService,
    private datePipe: DatePipe,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2,
    private tableActionsDrawerService: TableActionsDrawerService,
    private currencyPipe: CurrencyPipe
  ) { } // constructor


  ngOnInit() {
    this.currentDate = new Date();

    // Load the locations first
    this.getHospitalLocations(`${environment.slug}`).then(() => {
      // Check if there's a saved location in localStorage
      //const savedLocation = localStorage.getItem('selectedLocation');
      const savedLocation = localStorage.getItem('selectedLocation');
      if (savedLocation) {

        this.selectedLocation = +savedLocation; // Convert string to number
        // Fetch the data for the saved location
        this.getUsersAtLocation(this.selectedLocation);
      }
      this.is_loading_table = false
    });

    // Initialize DataSources
    this.dataSourcePreRegistered = new MatTableDataSource<HospitalLocationUserInterface>([]);
    this.dataSourceInProgress = new MatTableDataSource<HospitalLocationUserInterface>([]);
    this.dataSourceCheckedIn = new MatTableDataSource<HospitalLocationUserInterface>([]);

    this.dataSourcePreRegistered.filterPredicate = this.createFilterPredicate();
    this.dataSourceInProgress.filterPredicate = this.createFilterPredicate();
    this.dataSourceCheckedIn.filterPredicate = this.createFilterPredicate();

    //start polling session
    this.startPolling();

    // Set up ResizeObserver for adjusting table heights
    this.resizeObserver = new ResizeObserver(() => {
      this.adjustTableHeight();
    });

    document.querySelectorAll('.panel.mat-expanded').forEach(panelElement => {
      if (this.resizeObserver) {
        this.resizeObserver.observe(panelElement);
      }
    });
    this.subscription = this.tableActionsDrawerService.triggerAction$.subscribe(data => {
      // Handle the action trigger
      const updatedRow = data.row
      this.isFromProcessAppt = true
      this.currentRowData = [updatedRow]
      this.selectMoveEvent(data.source_status, data.target_status);
    });
  }
  // ngOnInit

  ngAfterViewInit() {
    // Subscribe to changes in the QueryList to handle dynamic addition of matSort instances
    this.sort.changes.subscribe(() => this.initializeSorts());

    // Initialize sorts if already present
    this.initializeSorts();
  }

  initializeSorts() {
    const sortArray = this.sort?.toArray();

    if (sortArray?.length >= 3) {
      this.dataSourcePreRegistered.sort = sortArray[0];
      this.dataSourceInProgress.sort = sortArray[1];
      this.dataSourceCheckedIn.sort = sortArray[2];

      // Adjust table height
      this.adjustTableHeight();

      // Attach sort change event handlers
      this.dataSourcePreRegistered.sort.sortChange.subscribe((sort: MatSort) => {
        this.sortStatePreRegistered = { active: sort.active, direction: sort.direction as 'asc' | 'desc' };
      });

      this.dataSourceInProgress.sort.sortChange.subscribe((sort: MatSort) => {
        this.sortStateInProgress = { active: sort.active, direction: sort.direction as 'asc' | 'desc' };
      });

      this.dataSourceCheckedIn.sort.sortChange.subscribe((sort: MatSort) => {
        this.sortStateCheckedIn = { active: sort.active, direction: sort.direction as 'asc' | 'desc' };
      });
    }
  }

  ngAfterContentChecked() {
    this.adjustTableHeight();
  }

  convertTimeToUnix(timeString: string): number {
    // Clean up extra spaces from the time string (e.g., "12: 00 PM" -> "12:00 PM")
    const cleanedTimeStr = timeString.replace(/\s+/g, ' ').trim();

    const [time, modifier] = cleanedTimeStr.split(' '); // Split time and AM/PM
    let [hours, minutes] = time.split(':').map(Number); // Split hours and minutes

    // Special case for 12 AM or PM
    if (hours === 12) {
      hours = 0;
    }

    // Convert PM to 24-hour format
    if (modifier === 'PM') {
      hours += 12;
    }

    const date = new Date();
    date.setHours(hours, minutes, 0, 0); // Set hours and minutes on the current date
     // Return Unix timestamp for sorting
    return date.getTime();
  }


  adjustTableHeight() {
    setTimeout(() => {
      const panels = document.querySelectorAll('.panel.mat-expanded .table-wrapper');
      panels.forEach(panel => {
        const panelElement = panel.closest('.panel.mat-expanded');
        if (panelElement) {
          const panelHeight = panelElement.clientHeight;
          const headerHeight = 56;  // Adjust if needed based on your specific header height
          const newHeight = `${panelHeight - headerHeight}px`;

          // Apply max-height with a smooth transition
          this.renderer.setStyle(panel, 'max-height', newHeight);
        }
      });
    }, 100);
  }

  @HostListener('window:resize')
  onResize() {
    this.adjustTableHeight();
  }
  @ViewChildren(MatExpansionPanel) panels!: QueryList<MatExpansionPanel>;

  onPanelToggled(panel: string) {
    this.adjustTableHeight();
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    // clear interval for getData()
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.subscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    // if (this.dataSubscription) {
    //   this.dataSubscription.unsubscribe();
    // }
  }

  // addDummyData() {
  //   this.pre_registered_data = [
  //     { client_id: 1, client_name: 'John Doe', date_of_birth: '1990-01-01', chart_number: '12345', user_id: 101, location_id: 1001, hospital_id: 1 },
  //     { client_id: 2, client_name: 'Jane Smith', date_of_birth: '1985-02-14', chart_number: '67890', user_id: 102, location_id: 1001, hospital_id: 1 },
  //     { client_id: 3, client_name: 'Alice Johnson', date_of_birth: '1975-05-30', chart_number: '11223', user_id: 103, location_id: 1002, hospital_id: 1 },
  //     { client_id: 4, client_name: 'Bob Brown', date_of_birth: '1968-09-23', chart_number: '44556', user_id: 104, location_id: 1003, hospital_id: 1 },
  //     { client_id: 5, client_name: 'Charlie Williams', date_of_birth: '1988-03-22', chart_number: '77889', user_id: 105, location_id: 1001, hospital_id: 1 },
  //     { client_id: 6, client_name: 'Emily Davis', date_of_birth: '1972-11-08', chart_number: '33445', user_id: 106, location_id: 1002, hospital_id: 1 },
  //     { client_id: 7, client_name: 'Frank Miller', date_of_birth: '1992-07-12', chart_number: '55667', user_id: 107, location_id: 1003, hospital_id: 1 },
  //     { client_id: 8, client_name: 'Grace Wilson', date_of_birth: '1983-01-15', chart_number: '88990', user_id: 108, location_id: 1001, hospital_id: 1 },
  //     { client_id: 9, client_name: 'Hannah Moore', date_of_birth: '1977-08-09', chart_number: '11234', user_id: 109, location_id: 1002, hospital_id: 1 },
  //     { client_id: 10, client_name: 'Ian Clark', date_of_birth: '1969-12-17', chart_number: '22345', user_id: 110, location_id: 1003, hospital_id: 1 },
  //     { client_id: 11, client_name: 'Jack Lewis', date_of_birth: '1981-04-05', chart_number: '33456', user_id: 111, location_id: 1001, hospital_id: 1 },
  //     { client_id: 12, client_name: 'Kate Harris', date_of_birth: '1974-06-21', chart_number: '44567', user_id: 112, location_id: 1002, hospital_id: 1 },
  //     { client_id: 13, client_name: 'Liam Young', date_of_birth: '1995-09-14', chart_number: '55678', user_id: 113, location_id: 1003, hospital_id: 1 },
  //     { client_id: 14, client_name: 'Mia King', date_of_birth: '1980-02-26', chart_number: '66789', user_id: 114, location_id: 1001, hospital_id: 1 },
  //     { client_id: 15, client_name: 'Noah Scott', date_of_birth: '1979-10-13', chart_number: '77890', user_id: 115, location_id: 1002, hospital_id: 1 },
  //     { client_id: 16, client_name: 'Olivia Hall', date_of_birth: '1986-05-19', chart_number: '88901', user_id: 116, location_id: 1003, hospital_id: 1 },
  //     { client_id: 17, client_name: 'Paul Adams', date_of_birth: '1967-11-22', chart_number: '99012', user_id: 117, location_id: 1001, hospital_id: 1 },
  //     { client_id: 18, client_name: 'Quinn Baker', date_of_birth: '1982-07-07', chart_number: '10123', user_id: 118, location_id: 1002, hospital_id: 1 },
  //     { client_id: 19, client_name: 'Rachel Evans', date_of_birth: '1970-03-03', chart_number: '11234', user_id: 119, location_id: 1003, hospital_id: 1 },
  //     { client_id: 20, client_name: 'Samuel Turner', date_of_birth: '1993-12-25', chart_number: '22345', user_id: 120, location_id: 1001, hospital_id: 1 },
  //     { client_id: 21, client_name: 'Tina Collins', date_of_birth: '1978-08-15', chart_number: '33456', user_id: 121, location_id: 1002, hospital_id: 1 },
  //     { client_id: 22, client_name: 'Uma Murphy', date_of_birth: '1989-10-30', chart_number: '44567', user_id: 122, location_id: 1003, hospital_id: 1 },
  //     { client_id: 23, client_name: 'Victor Reed', date_of_birth: '1973-09-17', chart_number: '55678', user_id: 123, location_id: 1001, hospital_id: 1 },
  //     { client_id: 24, client_name: 'Wendy Bennett', date_of_birth: '1991-04-09', chart_number: '66789', user_id: 124, location_id: 1002, hospital_id: 1 },
  //     { client_id: 25, client_name: 'Xander Price', date_of_birth: '1984-02-22', chart_number: '77890', user_id: 125, location_id: 1003, hospital_id: 1 },
  //     { client_id: 26, client_name: 'Yara Morgan', date_of_birth: '1987-11-10', chart_number: '88901', user_id: 126, location_id: 1001, hospital_id: 1 },
  //     { client_id: 27, client_name: 'Zane Perry', date_of_birth: '1971-06-04', chart_number: '99012', user_id: 127, location_id: 1002, hospital_id: 1 },
  //     { client_id: 28, client_name: 'Abby Scott', date_of_birth: '1990-12-12', chart_number: '10123', user_id: 128, location_id: 1003, hospital_id: 1 },
  //     { client_id: 29, client_name: 'Brian Walker', date_of_birth: '1966-03-18', chart_number: '11234', user_id: 129, location_id: 1001, hospital_id: 1 },
  //     { client_id: 30, client_name: 'Catherine Phillips', date_of_birth: '1985-09-25', chart_number: '22345', user_id: 130, location_id: 1002, hospital_id: 1 },
  //   ];
  //
  //   this.registration_in_progress_data = [
  //     { client_id: 31, client_name: 'Daniel Wright', date_of_birth: '1982-04-04', chart_number: '33456', user_id: 131, location_id: 1003, hospital_id: 1 },
  //     { client_id: 32, client_name: 'Evelyn Hill', date_of_birth: '1994-07-13', chart_number: '44567', user_id: 132, location_id: 1001, hospital_id: 1 },
  //     { client_id: 33, client_name: 'Finn Green', date_of_birth: '1979-02-17', chart_number: '55678', user_id: 133, location_id: 1002, hospital_id: 1 },
  //     { client_id: 34, client_name: 'George Wright', date_of_birth: '1983-11-09', chart_number: '66789', user_id: 134, location_id: 1003, hospital_id: 1 },
  //     { client_id: 35, client_name: 'Hazel Carter', date_of_birth: '1995-06-27', chart_number: '77890', user_id: 135, location_id: 1001, hospital_id: 1 },
  //     { client_id: 36, client_name: 'Ivy Robinson', date_of_birth: '1986-10-16', chart_number: '88901', user_id: 136, location_id: 1002, hospital_id: 1 },
  //     { client_id: 37, client_name: 'Jackie Foster', date_of_birth: '1977-01-24', chart_number: '99012', user_id: 137, location_id: 1003, hospital_id: 1 },
  //     { client_id: 38, client_name: 'Kyle Price', date_of_birth: '1990-08-19', chart_number: '10123', user_id: 138, location_id: 1001, hospital_id: 1 },
  //     { client_id: 39, client_name: 'Laura Barnes', date_of_birth: '1981-03-07', chart_number: '11234', user_id: 139, location_id: 1002, hospital_id: 1 },
  //     { client_id: 40, client_name: 'Mason Gray', date_of_birth: '1993-12-31', chart_number: '22345', user_id: 140, location_id: 1003, hospital_id: 1 },
  //     { client_id: 41, client_name: 'Nina Cook', date_of_birth: '1987-05-20', chart_number: '33456', user_id: 141, location_id: 1001, hospital_id: 1 },
  //     { client_id: 42, client_name: 'Oliver King', date_of_birth: '1976-09-01', chart_number: '44567', user_id: 142, location_id: 1002, hospital_id: 1 },
  //     { client_id: 43, client_name: 'Penny Lopez', date_of_birth: '1988-04-14', chart_number: '55678', user_id: 143, location_id: 1003, hospital_id: 1 },
  //     { client_id: 44, client_name: 'Quincy Allen', date_of_birth: '1973-10-03', chart_number: '66789', user_id: 144, location_id: 1001, hospital_id: 1 },
  //     { client_id: 45, client_name: 'Ruby Mitchell', date_of_birth: '1991-06-29', chart_number: '77890', user_id: 145, location_id: 1002, hospital_id: 1 },
  //     { client_id: 46, client_name: 'Sarah Young', date_of_birth: '1972-02-10', chart_number: '88901', user_id: 146, location_id: 1003, hospital_id: 1 },
  //     { client_id: 47, client_name: 'Tyler Harris', date_of_birth: '1997-12-05', chart_number: '99012', user_id: 147, location_id: 1001, hospital_id: 1 },
  //     { client_id: 48, client_name: 'Uma Parker', date_of_birth: '1989-07-22', chart_number: '10123', user_id: 148, location_id: 1002, hospital_id: 1 },
  //     { client_id: 49, client_name: 'Violet Carter', date_of_birth: '1984-10-14', chart_number: '11234', user_id: 149, location_id: 1003, hospital_id: 1 },
  //     { client_id: 50, client_name: 'Wyatt Bell', date_of_birth: '1992-05-26', chart_number: '22345', user_id: 150, location_id: 1001, hospital_id: 1 },
  //     { client_id: 51, client_name: 'Xena Ross', date_of_birth: '1980-11-18', chart_number: '33456', user_id: 151, location_id: 1002, hospital_id: 1 },
  //     { client_id: 52, client_name: 'Yasmin Lee', date_of_birth: '1974-03-05', chart_number: '44567', user_id: 152, location_id: 1003, hospital_id: 1 },
  //     { client_id: 53, client_name: 'Zach Quinn', date_of_birth: '1996-09-21', chart_number: '55678', user_id: 153, location_id: 1001, hospital_id: 1 },
  //     { client_id: 54, client_name: 'Abigail Stewart', date_of_birth: '1985-01-02', chart_number: '66789', user_id: 154, location_id: 1002, hospital_id: 1 },
  //     { client_id: 55, client_name: 'Ben Foster', date_of_birth: '1971-08-08', chart_number: '77890', user_id: 155, location_id: 1003, hospital_id: 1 },
  //   ];
  //
  //   this.checked_id_data = [
  //     { client_id: 56, client_name: 'Cindy Turner', date_of_birth: '1983-10-29', chart_number: '88901', user_id: 156, location_id: 1001, hospital_id: 1 },
  //     { client_id: 57, client_name: 'David Robinson', date_of_birth: '1998-12-12', chart_number: '99012', user_id: 157, location_id: 1002, hospital_id: 1 },
  //     { client_id: 58, client_name: 'Ella Hall', date_of_birth: '1981-04-24', chart_number: '10123', user_id: 158, location_id: 1003, hospital_id: 1 },
  //     { client_id: 59, client_name: 'Finnley Adams', date_of_birth: '1976-11-11', chart_number: '11234', user_id: 159, location_id: 1001, hospital_id: 1 },
  //     { client_id: 60, client_name: 'Georgia Martin', date_of_birth: '1992-07-07', chart_number: '22345', user_id: 160, location_id: 1002, hospital_id: 1 },
  //     { client_id: 61, client_name: 'Harry Wilson', date_of_birth: '1987-06-17', chart_number: '33456', user_id: 161, location_id: 1003, hospital_id: 1 },
  //     { client_id: 62, client_name: 'Isla Clark', date_of_birth: '1980-08-08', chart_number: '44567', user_id: 162, location_id: 1001, hospital_id: 1 },
  //     { client_id: 63, client_name: 'Jack Perry', date_of_birth: '1975-03-22', chart_number: '55678', user_id: 163, location_id: 1002, hospital_id: 1 },
  //     { client_id: 64, client_name: 'Kara Price', date_of_birth: '1989-05-14', chart_number: '66789', user_id: 164, location_id: 1003, hospital_id: 1 },
  //     { client_id: 65, client_name: 'Leo Bennett', date_of_birth: '1991-02-05', chart_number: '77890', user_id: 165, location_id: 1001, hospital_id: 1 },
  //   ];
  //
  //   // Initialize data sources with the dummy data
  //   this.dataSourcePreRegistered = new MatTableDataSource(this.pre_registered_data);
  //   this.dataSourceInProgress = new MatTableDataSource(this.registration_in_progress_data);
  //   this.dataSourceCheckedIn = new MatTableDataSource(this.checked_id_data);
  // }


  toggleDrawer(event: MouseEvent, table: string): void {
    event.stopPropagation();
    if (table === 'pre-registered' && this.tableSettingsDrawerPreRegistered) {
      this.tableSettingsDrawerPreRegistered.openDrawer();
    } else if (table === 'in-progress' && this.tableSettingsDrawerInProgress) {
      this.tableSettingsDrawerInProgress.openDrawer();
    } else if (table === 'checked-in' && this.tableSettingsDrawerCheckedIn) {
      this.tableSettingsDrawerCheckedIn.openDrawer();
    }
  }

  updateDisplayedColumnsPreRegistered(): void {
    this.displayedColumnsPreRegistered = this.allColumnsPreRegistered
      .filter(col => col.visible)
      .map(col => col.name);
    this.dataSourcePreRegistered = new MatTableDataSource(this.pre_registered_data); // Reinitialize
  }

  updateDisplayedColumnsInProgress(): void {
    this.displayedColumnsInProgress = this.allColumnsInProgress
      .filter(col => col.visible)
      .map(col => col.name);
    this.dataSourceInProgress = new MatTableDataSource(this.registration_in_progress_data); // Reinitialize
  }

  updateDisplayedColumnsCheckedIn(): void {
    this.displayedColumnsCheckedIn = this.allColumnsCheckedIn
      .filter(col => col.visible)
      .map(col => col.name);
    this.dataSourceCheckedIn = new MatTableDataSource(this.checked_id_data); // Reinitialize
  }


  saveColumnStatePreRegistered(): void {
    const columnState = {
      order: this.allColumnsPreRegistered.map(col => col.name),
      visibility: this.allColumnsPreRegistered.reduce((acc, col) => {
        acc[col.name] = col.visible;
        return acc;
      }, {} as { [key: string]: boolean }),
    };
    localStorage.setItem('columnStatePreRegistered', JSON.stringify(columnState));
  }

  saveColumnStateInProgress(): void {
    const columnState = {
      order: this.allColumnsInProgress.map(col => col.name),
      visibility: this.allColumnsInProgress.reduce((acc, col) => {
        acc[col.name] = col.visible;
        return acc;
      }, {} as { [key: string]: boolean }),
    };
    localStorage.setItem('columnStateInProgress', JSON.stringify(columnState));
  }

  saveColumnStateCheckedIn(): void {
    const columnState = {
      order: this.allColumnsCheckedIn.map(col => col.name),
      visibility: this.allColumnsCheckedIn.reduce((acc, col) => {
        acc[col.name] = col.visible;
        return acc;
      }, {} as { [key: string]: boolean }),
    };
    localStorage.setItem('columnStateCheckedIn', JSON.stringify(columnState));
  }

  loadColumnStatePreRegistered(): void {
    const savedState = localStorage.getItem('columnStatePreRegistered');
    if (savedState) {
      const columnState = JSON.parse(savedState);
      this.allColumnsPreRegistered.forEach(col => {
        col.visible = columnState.visibility[col.name];
      });

      this.allColumnsPreRegistered.sort((a, b) => {
        return columnState.order.indexOf(a.name) - columnState.order.indexOf(b.name);
      });

      this.updateDisplayedColumnsPreRegistered();
    }
  }

  loadColumnStateInProgress(): void {
    const savedState = localStorage.getItem('columnStateInProgress');
    if (savedState) {
      const columnState = JSON.parse(savedState);
      this.allColumnsInProgress.forEach(col => {
        col.visible = columnState.visibility[col.name];
      });

      this.allColumnsInProgress.sort((a, b) => {
        return columnState.order.indexOf(a.name) - columnState.order.indexOf(b.name);
      });

      this.updateDisplayedColumnsInProgress();
    }
  }

  loadColumnStateCheckedIn(): void {
    const savedState = localStorage.getItem('columnStateCheckedIn');
    if (savedState) {
      const columnState = JSON.parse(savedState);
      this.allColumnsCheckedIn.forEach(col => {
        col.visible = columnState.visibility[col.name];
      });

      this.allColumnsCheckedIn.sort((a, b) => {
        return columnState.order.indexOf(a.name) - columnState.order.indexOf(b.name);
      });

      this.updateDisplayedColumnsCheckedIn();
    }
  }

  toggleColumnVisibilityPreRegistered(column: any): void {
    column.visible = !column.visible;
    this.updateDisplayedColumnsPreRegistered();
    this.saveColumnStatePreRegistered();
    // Refresh the data source to apply the column changes
    this.dataSourcePreRegistered = new MatTableDataSource(this.pre_registered_data);
  }

  toggleColumnVisibilityInProgress(column: any): void {
    column.visible = !column.visible;
    this.updateDisplayedColumnsInProgress();
    this.saveColumnStateInProgress();
    // Refresh the data source to apply the column changes
    this.dataSourceInProgress = new MatTableDataSource(this.registration_in_progress_data);
  }

  toggleColumnVisibilityCheckedIn(column: any): void {
    column.visible = !column.visible;
    this.updateDisplayedColumnsCheckedIn();
    this.saveColumnStateCheckedIn();
    // Refresh the data source to apply the column changes
    this.dataSourceCheckedIn = new MatTableDataSource(this.checked_id_data);
  }

  dropPreRegistered(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.allColumnsPreRegistered, event.previousIndex, event.currentIndex);
    this.updateDisplayedColumnsPreRegistered();
    this.saveColumnStatePreRegistered();
    // Refresh the data source to apply the column changes
    this.dataSourcePreRegistered = new MatTableDataSource(this.pre_registered_data);
  }

  dropInProgress(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.allColumnsInProgress, event.previousIndex, event.currentIndex);
    this.updateDisplayedColumnsInProgress();
    this.saveColumnStateInProgress();
    // Refresh the data source to apply the column changes
    this.dataSourceInProgress = new MatTableDataSource(this.registration_in_progress_data);
  }

  dropCheckedIn(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.allColumnsCheckedIn, event.previousIndex, event.currentIndex);
    this.updateDisplayedColumnsCheckedIn();
    this.saveColumnStateCheckedIn();
    // Refresh the data source to apply the column changes
    this.dataSourceCheckedIn = new MatTableDataSource(this.checked_id_data);
  }


  location_id_lookup: object = {}
  getHospitalLocations(slug: string): Promise<void> {
    this.is_loading_table = true
    return new Promise((resolve, reject) => {
      let endpoint = `registration/location/${slug}`;
      this.registrationService.getLocations(endpoint).subscribe(
        (data: HospitalLocationInterface[]) => {
          // @ts-ignore
          this.locations = data['message'];
          for (let i = 0; i < this.locations.length; i++) {
            // @ts-ignore
            this.location_id_lookup[this.locations[i]['location_id']] = this.locations[i];
          }
          this.hospital_id = this.locations[0]['hospital_id']; // Get the first hospital ID

          resolve();
        },
        (error) => {
          this.is_loading_table = false;
          reject(error);
        }
      );
    });
  }  // getHospitalLocations

  getUsersAtLocation(location_id: number) {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.location_id = location_id;
    this.refresh$.next({
      date: this.currentDate,
      locationId: location_id
    });

    if (this.dataSourcePreRegistered.data.length === 0) {
      this.selection_pre_registered.clear();
    }
    if (this.dataSourceInProgress.data.length === 0) {
      this.selection_in_progress.clear();
    }
    if (this.dataSourceCheckedIn.data.length === 0) {
      this.selection_checked_in.clear();
    }
  }

  isDateType(date: Date): string {
    const today = new Date();
    // Set the hours, minutes, seconds, and milliseconds to 0 to ignore the time part
    today.setHours(0, 0, 0, 0);
    date.setHours(0, 0, 0, 0);
    if (date > today) {
      return 'futureDate';
    } else if (date < today) {
      return 'pastDate';
    } else {
      return 'currentDate'
    }
  }

  clearAllData() {
    // Clear all data arrays
    this.pre_registered_data = [];
    this.checked_id_data = [];
    this.registration_in_progress_data = [];

    // Reset data sources
    this.dataSourcePreRegistered = new MatTableDataSource<HospitalLocationUserInterface>([]);
    this.dataSourceInProgress = new MatTableDataSource<HospitalLocationUserInterface>([]);
    this.dataSourceCheckedIn = new MatTableDataSource<HospitalLocationUserInterface>([]);

    // Clear selections
    this.selection_pre_registered.clear();
    this.selection_in_progress.clear();
    this.selection_checked_in.clear();
  }

  onDateChange(event: any, currentDate: any) {
    this.clearAllData()
    if (this.location_id >= 0) {
      this.refresh$.next({
        date: this.currentDate,
        locationId: this.location_id
      });
      if (this.dataSourcePreRegistered.data.length === 0) {
        this.selection_pre_registered.clear();
      }
      if (this.dataSourceInProgress.data.length === 0) {
        this.selection_in_progress.clear();
      }
      if (this.dataSourceCheckedIn.data.length === 0) {
        this.selection_checked_in.clear();
      }
      this.applyGlobalFilter(this.searchText);
    }

    if (this.isDateType(new Date(currentDate)) === 'futureDate') {
      this.panelExpanded['in-progress'] = false;
      this.panelExpanded['checked-in'] = false;
      this.isInProgressEnabled = false;
      this.isCheckedInEnabled = false;
      this.isPreRegisteredEnabled = true;
      this.panelExpanded['pre-registered'] = true;
      this.showActionButtons = false;
    } else if (this.isDateType(new Date(currentDate)) === 'pastDate') {
      this.isPreRegisteredEnabled = false;
      this.isInProgressEnabled = false;
      this.isCheckedInEnabled = true;
      this.panelExpanded['checked-in'] = true;
      this.showActionButtons = false;
    } else {
      this.panelExpanded['in-progress'] = true;
      this.panelExpanded['checked-in'] = true;
      this.isPreRegisteredEnabled = true;
      this.isInProgressEnabled = true;
      this.isCheckedInEnabled = true;
      this.showActionButtons = true;
    }
  }

  calculateTotalPayment(payment_json: any) : string {
    const collectedForAppointment = parseFloat(payment_json?.collectedforappointment) || 0;
    const collectedForOther = parseFloat(payment_json?.collectedforother) || 0;
    const insuranceCopay = parseFloat(payment_json?.insurancecopay) || 0;
    const totalPayment = collectedForAppointment + collectedForOther + insuranceCopay;
    if (totalPayment == 0 && payment_json?.copay == null){
      return "-"
    }
    return "$"+totalPayment.toFixed(1);
}
  calculateTotalbalance(payment_json: any) : string {

    if (!payment_json) {
      payment_json = {};
    }

    // const collectedForOther = parseFloat(payment_json?.collectedforother) || 0;
    // const copay = parseFloat(payment_json?.copay) || 0;

    // const totalBalance = copay + collectedForOther;
    const totalBalance =  parseFloat(payment_json?.client_balance) || 0;
    // if (totalBalance == 0 && payment_json?.copay == null){
    //   return "-"
    // }
    return totalBalance.toFixed(2);
  }

  // updated polling mechanism to cancel the polling when location or date changes and new polling starts with new parameters so that it doesnt interfere from old data
  startPolling() {
    this.refresh$.pipe(
      takeUntil(this.destroy$),
      filter(({locationId}) => locationId >= 0),
      switchMap((params) => {
        // Initial immediate fetch
        return interval(5000).pipe(
          startWith(0),
          switchMap(() => this.getData(params)),
          distinctUntilChanged((prev, curr) => JSON.stringify(prev) === JSON.stringify(curr)),
          filter(data => !!data)
        );
      })
    ).subscribe(data => {
      this.updateTableData(data);
    });
  }

  openLink(url: string, row?: HospitalLocationUserInterface | undefined, event?: MouseEvent, action?: string): void {
    event?.stopPropagation()
    if (false) {
      const fullUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `https://${url}`;
      window.open(fullUrl, '_blank');
    } else {
      this.actionsDrawer.openDrawer();
      this.selectedAction = 'patient-name';
      const allAppointments = this.userData$.getValue().filter(item => item.client_id === row?.client_id).sort((a, b) => a.appointment_unixts - b.appointment_unixts);;
      // Create an array of newRow objects for each appointment
      const appointmentRows = allAppointments.map(appointment => ({
        name: appointment.client_name,
        chartNumber: appointment.chart_number,
        meetingId: appointment.meeting_id,
        provider: appointment.surgeon_name,
        apptType: appointment.appointment_type,
        apptTime: appointment.appointment_time,
        contactDetails: appointment.contact_details,
        patientNotes: appointment.alerts.patient_notes || {},
        visitNotes: appointment.alerts.meeting_notes || {},
        patientId: appointment.client_id,
        athenaNotes: appointment.alerts.athena_notes,
        payment_details: appointment.payment_details,
        alerts: appointment.alerts
      }));
      this.selectedRow = appointmentRows;
      this.selectedAction = action || '';
      this.rawRow = row || {} as HospitalLocationUserInterface;
    }
  }

  getData(params?: { date: Date | undefined, locationId: number}) {
    const currentDateValue = params?.date || this.currentDate;
    const locationId = params?.locationId || this.location_id;
    const data_post = this.datePipe.transform(currentDateValue, 'yyyy-MM-dd') || '';
    // @ts-ignore
    this.timezone_name = this.location_id_lookup[locationId]['timezone'];
    const endpoint = `registration/location/user/${this.hospital_id}/${locationId}`;
    const data: HospitalLocationPostInterfaceData = {
      date: data_post,
      timezone_name: this.timezone_name
    };

    return this.registrationService.postLocations(endpoint, data).pipe(
      map((response: { message: HospitalLocationInterface[], status: number}) => {
        return response && response.message ? response : null;
      })
    );
  }

  getColorClass(activityScore: any): string {
    const score = typeof activityScore === 'string' ? parseFloat(activityScore) : activityScore;

    if (score === 0) {
      return 'random-black';
    } else if (score > 0 && score <= 40) {
      return 'random-grey';
    } else if (score > 40 && score <= 60) {
      return 'random-orange';
    } else if (score > 60) {
      return 'random-green';
    }
    return 'random-black';
  }

  updateTableData(response: any) {
    if (!response || !response.message) return;

    const meta: HospitalLocationUserInterface[] = response.message;
    this.userData$.next([...response.message]);
    // Reset data arrays
    this.pre_registered_data = [];
    this.checked_id_data = [];
    this.registration_in_progress_data = [];
    let idx = 0;

    const preRegisteredSelected = this.selection_pre_registered.selected;
    const inProgressSelected = this.selection_in_progress.selected;
    const checkedInSelected = this.selection_checked_in.selected;


  // const userId = localStorage.getItem('email');
  // const lastFilter = localStorage.getItem(`filters_${userId}_last`);
  // const savedFiltersData = localStorage.getItem(`filters_${userId}_saved`);
  // let savedFilters: Record<string, any> = {};

  // if (savedFiltersData) {
  //   savedFilters = JSON.parse(savedFiltersData);
  // }

  // // If there's a last selected filter, apply it
  // if (lastFilter && savedFilters[lastFilter]) {
  //   const filterSelections = savedFilters[lastFilter];
  //   const filterArray: [string, string][] = [];

  //   Object.entries(filterSelections).forEach(([category, values]) => {
  //     if (Array.isArray(values)) {
  //       values.forEach(value => {
  //         filterArray.push([category, value]);
  //       });
  //     }
  //   });

  //   this.fiterBarFilters = JSON.stringify(filterArray);
  // }

  const latestRecords = Object.values(
    meta.reduce((map, item) => {
      if (
        !map[item.client_id] ||
        new Date(item.appointment_unixts) < new Date(map[item.client_id].appointment_unixts)
      ) {
        map[item.client_id] = item;
      }
      return map;
    }, {} as Record<string, HospitalLocationUserInterface>)
  );

    // loop for setting the balance copay and the alert icons
    latestRecords.forEach(item => {
      // Format balance using CurrencyPipe
      item.balance = this.currencyPipe.transform(
        this.calculateTotalbalance(item.payment_details),
        'USD',
        'symbol',
        '1.2-2'
      ) ?? '';

      // Format copay using CurrencyPipe
      item.payment_details.copay = item?.payment_details?.copay
        ? this.currencyPipe.transform(
          parseFloat(item.payment_details.copay),
          'USD',
          'symbol',
          '1.2-2'
        )
        : item?.payment_details?.copay;

      // Process alert icons
      item.alertIcons = this.getAlertsIcons(item.alerts);
      const colorClass = this.getColorClass(item.activity_score);

      if (!this.randomColorMap.has(item.meeting_id)) {
        this.randomColorMap.set(item.meeting_id, colorClass);
      }

      // Retrieve the stored color from the map
      item.randomColorClass = this.randomColorMap.get(item.meeting_id);
      item.marked = this.markedRowsMap.get(item.meeting_id) || item.alerts.notes_flag;
    });

    // filtering and asigning the data to the respective table
    this.pre_registered_data = latestRecords.filter(item => item.checkin_status === 'pre-registered');
    this.checked_id_data = latestRecords.filter(item => item.checkin_status === 'checked-in');
    this.registration_in_progress_data = latestRecords.filter(item => item.checkin_status === 'in-progress');

    // Update table data sources
    if (this.isPreRegisteredEnabled) {
      this.dataSourcePreRegistered = new MatTableDataSource(this.pre_registered_data);
      this.dataSourcePreRegistered.sort = this.sort.toArray()[idx];
      this.dataSourcePreRegistered.sort.active = this.sortStatePreRegistered.active;
      this.dataSourcePreRegistered.sort.direction = this.sortStatePreRegistered.direction;
      this.dataSourcePreRegistered.filterPredicate = this.createFilterPredicate();
      this.selection_pre_registered.clear();
      preRegisteredSelected.forEach(selected => {
        const matchingRow = this.pre_registered_data.find(row => row.meeting_id === selected.meeting_id);
        if (matchingRow) {
          this.selection_pre_registered.select(matchingRow);
        }
      });
      idx = idx + 1
    }

    if (this.isInProgressEnabled) {
      this.dataSourceInProgress = new MatTableDataSource(this.registration_in_progress_data);
      this.dataSourceInProgress.sort = this.sort.toArray()[idx];
      this.dataSourceInProgress.sort.active = this.sortStateInProgress.active;
      this.dataSourceInProgress.sort.direction = this.sortStateInProgress.direction;
      this.dataSourceInProgress.filterPredicate = this.createFilterPredicate();
      this.selection_in_progress.clear();
      inProgressSelected.forEach(selected => {
        const matchingRow = this.registration_in_progress_data.find(row => row.meeting_id === selected.meeting_id);
        if (matchingRow) {
          this.selection_in_progress.select(matchingRow);
        }
      });
      idx = idx + 1;
    }

    if (this.isCheckedInEnabled) {
      this.dataSourceCheckedIn = new MatTableDataSource(this.checked_id_data);
      this.dataSourceCheckedIn.sort = this.sort.toArray()[idx];
      this.dataSourceCheckedIn.sort.active = this.sortStateCheckedIn.active;
      this.dataSourceCheckedIn.sort.direction = this.sortStateCheckedIn.direction;
      this.dataSourceCheckedIn.filterPredicate = this.createFilterPredicate();
      this.selection_checked_in.clear();
      checkedInSelected.forEach(selected => {
        const matchingRow = this.checked_id_data.find(row => row.meeting_id === selected.meeting_id);
        if (matchingRow) {
          this.selection_checked_in.select(matchingRow);
        }
      });
    }

    this.is_loading_table = false;
    this.applyGlobalFilter(this.searchText);
    if (this.fiterBarFilters) {
      this.dataSourceInProgress.filter = this.fiterBarFilters;
      this.dataSourceCheckedIn.filter = this.fiterBarFilters;
      this.dataSourcePreRegistered.filter = this.fiterBarFilters;
    }
    if (this.dataSourcePreRegistered?.data.length === 0) {
      this.selection_pre_registered.clear();
    }
    if (this.dataSourceInProgress?.data.length === 0) {
      this.selection_in_progress.clear();
    }
    if (this.dataSourceCheckedIn?.data.length === 0) {
      this.selection_checked_in.clear();
    }
  }

  putStatus(data: StatusPutInterfaceData[]) {
    let endpoint = `registration/composite/${this.hospital_id}/${this.location_id}`
    // let data: StatusPutInterfaceData[] = [{
    //   "status": "checked-in",
    //   "meeting_id": form_id,
    // }]

    return this.registrationService.putStatus(endpoint, data)

  }

  onHospitalLocation(event: { locationId: number; phone: string | undefined; timezone?: string | undefined}): void {
    const { locationId, phone = '', timezone } = event;
    this.selectedLocation = locationId;
    this.selectedTimezone = timezone || ''
    console.log('Selected Location ID:', locationId);
    this.getUsersAtLocation(locationId);
    localStorage.setItem('selectedLocation', locationId.toString());
    localStorage.setItem('locationPhoneNumber', phone.toString());
    this.applyGlobalFilter(this.searchText);
  }
  // onHospitalLocation

  getTableRecord(row: object) {
  } //end getTableRecord

  applyGlobalFilter(eventOrFilterValue: string | KeyboardEvent): void {
    let filterValue: string;

    if (typeof eventOrFilterValue === 'string') {
      filterValue = eventOrFilterValue;
    } else {
      const inputElement = eventOrFilterValue.target as HTMLInputElement;
      filterValue = inputElement.value;
    }

    filterValue = filterValue.trim().toLowerCase();

    // Applying search text while preserving filter bar selections
    [this.dataSourcePreRegistered, this.dataSourceInProgress, this.dataSourceCheckedIn].forEach(dataSource => {
      if (dataSource) {
        // Reset masks
        dataSource.data.forEach(row => row.mask = 1);
        dataSource.filterPredicate = this.createFilterPredicate();

        if (this.fiterBarFilters && filterValue) {
          // If both filters and search text exist, apply both
          const combinedFilter = {
            filters: JSON.parse(this.fiterBarFilters),
            searchText: filterValue
          };
          dataSource.filter = JSON.stringify(combinedFilter);
        } else if (this.fiterBarFilters) {
          // Only filter bar selections
          dataSource.filter = this.fiterBarFilters;
        } else if (filterValue) {
          // Only search text
          dataSource.filter = filterValue;
        } else {
          dataSource.filter = '';
        }
      }
    });

    // Clear selection if no data after filtering
    if (this.dataSourcePreRegistered.filteredData.length === 0) {
      this.selection_pre_registered.clear();
    }
    if (this.dataSourceInProgress.filteredData.length === 0) {
      this.selection_in_progress.clear();
    }
    if (this.dataSourceCheckedIn.filteredData.length === 0) {
      this.selection_checked_in.clear();
    }
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
    this.dataSourcePreRegistered.filter = filterValue;

    // Clear selection if no data after filtering
    if (this.dataSourcePreRegistered.filteredData.length === 0) {
      this.selection_pre_registered.clear();
    }
  }

  clearSearch(input: HTMLInputElement) {
    this.searchText = '';
    input.value = '';
    this.applyGlobalFilter('');
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.shiftKey && event.code === 'Space') {
      event.preventDefault();
      this.inputElement.nativeElement.focus();
    }
  }

  onMoveChange(status: string, row: HospitalLocationUserInterface, event: any) {
    const moveTo = event.value;

    let sourceDataArray: HospitalLocationUserInterface[] = [];
    let sourceDataSource: MatTableDataSource<HospitalLocationUserInterface> | undefined;
    let targetDataArray: HospitalLocationUserInterface[] = [];
    let targetDataSource: MatTableDataSource<HospitalLocationUserInterface> | undefined;

    // Determine the source arrays and data sources based on the status
    if (status === 'pre-registered') {
      sourceDataArray = this.pre_registered_data;
      sourceDataSource = this.dataSourcePreRegistered;
    } else if (status === 'in-progress') {
      sourceDataArray = this.registration_in_progress_data;
      sourceDataSource = this.dataSourceInProgress;
    } else if (status === 'checked-in') {
      sourceDataArray = this.checked_id_data;
      sourceDataSource = this.dataSourceCheckedIn;
    }

    // Determine the target arrays and data sources based on the moveTo value
    if (moveTo === 'pre-registered') {
      let data: StatusPutInterfaceData[] = [{
        "status": "pre-registered",
        "meeting_id": row.meeting_id,
      }]
      this.putStatus(data)
      targetDataArray = this.pre_registered_data;
      targetDataSource = this.dataSourcePreRegistered;
    } else if (moveTo === 'in-progress') {
      let data: StatusPutInterfaceData[] = [{
        "status": "in-progress",
        "meeting_id": row.meeting_id,
      }]
      this.putStatus(data)
      targetDataArray = this.registration_in_progress_data;
      targetDataSource = this.dataSourceInProgress;
    } else if (moveTo === 'checked-in') {
      let data: StatusPutInterfaceData[] = [{
        "status": "checked-in",
        "meeting_id": row.meeting_id,
      }]
      this.putStatus(data)
      targetDataArray = this.checked_id_data;
      targetDataSource = this.dataSourceCheckedIn;
    }

    // Remove the row from the source array and update the source data source
    if (sourceDataSource) {
      const index = sourceDataArray.indexOf(row);
      if (index > -1) {
        sourceDataArray.splice(index, 1);
        sourceDataSource.data = [...sourceDataArray]; // Update the source data source
      }
    }

    // Add the row to the target array and update the target data source
    if (targetDataSource) {
      targetDataArray.push(row);
      targetDataSource.data = [...targetDataArray]; // Update the target data source
    }

    // Force change detection to ensure the UI updates
    this.cdr.detectChanges();
  }



  // end onMoveChange

  getRowCount(dataSource: MatTableDataSource<any>): number {
    return dataSource.data.length;
  }

  toggleAccordion() {
    if (this.isAccordionOpen) {
      this.accordion().closeAll();
    } else {
      this.accordion().openAll();
    }
    this.isAccordionOpen = !this.isAccordionOpen;
  }


  /*****************************************CHECKBOX SELECTION**************************************/
  selection_pre_registered = new SelectionModel<HospitalLocationUserInterface>(true, []);
  selection_in_progress = new SelectionModel<HospitalLocationUserInterface>(true, []);
  selection_checked_in = new SelectionModel<HospitalLocationUserInterface>(true, []);
  isAllSelected(status: string) {

    let selection: SelectionModel<HospitalLocationUserInterface>;
    let dataSource: MatTableDataSource<HospitalLocationUserInterface>;
    if (status === 'pre-registered') {
      selection = this.selection_pre_registered;
      dataSource = this.dataSourcePreRegistered;
    } else if (status === 'in-progress') {
      selection = this.selection_in_progress;
      dataSource = this.dataSourceInProgress;
    } else if (status === 'checked-in') {
      selection = this.selection_checked_in
      dataSource = this.dataSourceCheckedIn;
    } else {
      return false; // handle the case where status is not recognized
    }

    const numSelected = selection?.selected?.length;

    dataSource.data = dataSource.filteredData.length > 0 ? dataSource.filteredData : dataSource.data;

    const numRows = dataSource?.data?.length;
    return numSelected === numRows;

  } // end isAllSelected

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows(status: string) {

    let selection: SelectionModel<HospitalLocationUserInterface>;
    let dataSource: MatTableDataSource<HospitalLocationUserInterface>;

    if (status === 'pre-registered') {
      selection = this.selection_pre_registered;
      dataSource = this.dataSourcePreRegistered;
    } else if (status === 'in-progress') {
      selection = this.selection_in_progress;
      dataSource = this.dataSourceInProgress;
    } else if (status === 'checked-in') {
      selection = this.selection_checked_in
      dataSource = this.dataSourceCheckedIn;
    } else {
      return;
    }

    dataSource.data = dataSource.filteredData.length > 0 ? dataSource.filteredData : dataSource.data;
    if (this.isAllSelected(status)) {
      selection.clear();
      return;
    }

    selection.select(...dataSource.data);
  } // end toggleAllRows

  /** The label for the checkbox on the passed row */
  checkboxLabel(status: string, row?: HospitalLocationUserInterface): string {

    let selection: SelectionModel<HospitalLocationUserInterface>;
    if (status === 'pre-registered') {
      selection = this.selection_pre_registered;
    } else if (status === 'in-progress') {
      selection = this.selection_in_progress;
    } else if (status === 'checked-in') {
      selection = this.selection_checked_in
    } else {
      return '';
    }

    if (!row) {
      return `${this.isAllSelected(status) ? 'deselect' : 'select'} all`;
    }
    return `${selection.isSelected(row) ? 'deselect' : 'select'} row ${row.hospital_id + 1}`;
  } // end checkboxLabel

  handleStatusClick(row: any, event: Event, source_status: string): void {
    if (row?.alerts?.form_status === 'complete' && source_status !== 'checked-in' && this.showActionButtons) {
      this.isFromProcessAppt = true
      this.currentRowData = [row]
      this.selectMoveEvent(source_status, 'checked-in');
    }
    event.stopPropagation();
  }

  // Method to get all selected rows
  getSelectedRows(source_status: string): HospitalLocationUserInterface[] {

    if (source_status === 'pre-registered') {
      return this.selection_pre_registered.selected;
    } else if (source_status === 'in-progress') {
      return this.selection_in_progress.selected;
    } else if (source_status === 'checked-in') {
      return this.selection_checked_in.selected;
    }
    return this.selection_pre_registered.selected;
  }

  selectMoveEvent(source_status: string, target_status: string) {
    let sourceDataSource: MatTableDataSource<HospitalLocationUserInterface>;
    let targetDataSource: MatTableDataSource<HospitalLocationUserInterface>;
    let target_data: HospitalLocationUserInterface[];

    if (source_status === 'pre-registered') {
      sourceDataSource = this.dataSourcePreRegistered;
    } else if (source_status === 'in-progress') {
      sourceDataSource = this.dataSourceInProgress;
    } else if (source_status === 'checked-in') {
      sourceDataSource = this.dataSourceCheckedIn;
    }

    if (target_status === 'pre-registered') {
      targetDataSource = this.dataSourcePreRegistered;
      target_data = this.pre_registered_data;
    } else if (target_status === 'in-progress') {

      targetDataSource = this.dataSourceInProgress;
      target_data = this.registration_in_progress_data;
    } else if (target_status === 'checked-in') {
      targetDataSource = this.dataSourceCheckedIn;
      target_data = this.checked_id_data;
    }

    // @ts-ignore
    this.moveSelectedRows(target_status, source_status, sourceDataSource, targetDataSource, target_data);


  } // end selectMove

  // Method to move selected rows to a different data source
  moveSelectedRows(target_status: string, source_status: string, sourceDataSource: MatTableDataSource<HospitalLocationUserInterface>, targetDataSource: MatTableDataSource<HospitalLocationUserInterface>, target_data: HospitalLocationUserInterface[]) {
    let selectedRows = []
    if (this.isFromProcessAppt) {
      selectedRows = this.currentRowData;
    } else {
      selectedRows = this.getSelectedRows(source_status);
    }

    let data: StatusPutInterfaceData[] = []

    // ASYNC UPDATE STATUS ON BACKEND
    for (let i = 0; i < selectedRows.length; i++) {
      if (this.isFromProcessAppt) {
        data.push({
          "status": target_status,
          "meeting_id": selectedRows[i].meeting_id,
          "manual_checkin": true
        })
      } else {
        data.push({
          "status": target_status,
          "meeting_id": selectedRows[i].meeting_id,
        })
      }
    }
    this.putStatus(data).subscribe((response) => {
      if (response.status === 200) {
        selectedRows.forEach(row => {
          // Remove the row from the current data source
          const index = sourceDataSource.data.indexOf(row);
          if (index > -1) {
            sourceDataSource.data.splice(index, 1);
            sourceDataSource._updateChangeSubscription();
            target_data.push(row);
            targetDataSource.data = target_data;
            targetDataSource._updateChangeSubscription();
          }
        });
      } else {
        console.error('Failed to move status', response);
      }
    }, (error) => {
      console.error('Error!!!!!!!', error)
    })


    // Clear the selection

    //this.selection.clear();
    if (source_status === 'pre-registered') {
      this.selection_pre_registered.clear();
    }
    if (source_status === 'in-progress') {
      this.selection_in_progress.clear();
    }
    if (source_status === 'checked-in') {
      this.selection_checked_in.clear();
    }

  } // end moveSelectedRows

  getDisplayedCount(table: string): string {
    let selection: SelectionModel<HospitalLocationUserInterface>;
    let dataSource: MatTableDataSource<HospitalLocationUserInterface>;
    let totalCount: number;
    let filteredCount: number;

    if (table === 'pre-registered') {
      selection = this.selection_pre_registered;
      dataSource = this.dataSourcePreRegistered;
      totalCount = this.pre_registered_data.length;
    } else if (table === 'in-progress') {
      selection = this.selection_in_progress;
      dataSource = this.dataSourceInProgress;
      totalCount = this.registration_in_progress_data.length;
    } else if (table === 'checked-in') {
      selection = this.selection_checked_in;
      dataSource = this.dataSourceCheckedIn;
      totalCount = this.checked_id_data.length;
    } else {
      return '0';
    }

    filteredCount = dataSource.filteredData.length;

    if (filteredCount === 0) {
      return '0'; // No data after filtering
    } else if (selection.hasValue()) {
      return `${selection.selected.length} selected`;
    } else if (dataSource.filter) {
      return `${filteredCount}`;
    } else {
      return `${totalCount}`;
    }
  }


  classState = {
    preRegistered: false,
    inProgress: false,
    checkedIn: false,
  };

  expandPanel(panel: string) {
    const allPanelsExpanded = Object.values(this.panelExpanded).every(value => value);
    const onlyOneExpanded = Object.keys(this.panelExpanded).filter(key => this.panelExpanded[key]).length === 1;

    if (allPanelsExpanded) {
      // Collapse all except the clicked panel
      this.panelExpanded = {
        'pre-registered': false,
        'in-progress': false,
        'checked-in': false
      };
      this.panelTooltips = {
        'pre-registered': 'Maximize',
        'in-progress': 'Maximize',
        'checked-in': 'Maximize'
      };
      this.panelExpanded[panel] = true;
      this.panelTooltips[panel] = 'Minimize'
    } else if (onlyOneExpanded && this.panelExpanded[panel]) {
      // Expand all panels if only one is expanded and the clicked panel is already expanded
      this.panelExpanded = {
        'pre-registered': true,
        'in-progress': true,
        'checked-in': true
      };
      this.panelTooltips = {
        'pre-registered': 'Maximize',
        'in-progress': 'Maximize',
        'checked-in': 'Maximize'
      };
    } else {
      // Collapse all except the clicked panel
      this.panelExpanded = {
        'pre-registered': false,
        'in-progress': false,
        'checked-in': false
      };
      this.panelTooltips = {
        'pre-registered': 'Maximize',
        'in-progress': 'Maximize',
        'checked-in': 'Maximize'
      };
      this.panelExpanded[panel] = true;
      this.panelTooltips[panel] = 'Minimize'
    }
    this.cdr.detectChanges();
  }



  getAppointmentTypeIcon(appointmentType: string): string {
    switch (appointmentType.toLowerCase()) {
      case 'new':
        return 'h-accent healent-path-3560';
      case 'follow-up':
        return 'h-primary healent-path-3561';
      default:
        return 'h-primary healent-calendar-fill';
    }
  }

  getAlertsIcons(alerts: AlertsInterface): string[] {
    const {
      is_birthday = 'false',
      multiple_appointments = { multiple_appointments_exist: 'false', appointments: []},
      athena_alerts = [],
      manual_checkin = false
    } = alerts || {};
    let icons: string[] = [];
    let allAthenaAlerts: AthenaAlertsInterface[] = Array.isArray(athena_alerts) ? [...athena_alerts] : [];
    if (is_birthday === 'true') {
      icons.push('healent-icon-5');
    }
    if (multiple_appointments?.multiple_appointments_exist === 'true') {
      icons.push('healent-path-3630');
      const userData = this.userData$.getValue();
      const additionalAlerts = multiple_appointments.appointments
      .map((meetingId) => userData.find((item) => item.meeting_id === meetingId)?.alerts?.athena_alerts)
      .filter((alerts): alerts is AthenaAlertsInterface[] => !!alerts) // Ensure non-null values and assert type
      .flat();
      allAthenaAlerts = [...allAthenaAlerts, ...additionalAlerts];
    }
    if (allAthenaAlerts?.filter((alert): any => !alert.ignored && alert.type === 'consent').length > 0) {
      icons.push('healent-path-3629');
    }

    if (allAthenaAlerts?.filter((alert): any => !alert.ignored).length > 0) {
      icons.push('healent-bell')
    }

    if (manual_checkin) {
      icons.push('healent-path-3633')
    }
    return icons;
  }

  getRegStatusIcon(status: string): string {
    switch (status.trim().toLowerCase()) {
      case 'not_started':
        return 'h-red healent-q-5';
      case 'in_progress':
        return 'h-warning healent-path-3623';
      case 'complete':
        return 'h-accent healent-no-issues';
      default:
        return 'healent-path-3620';
    }
  }

  getClentActivatedTimeToolTip(row: HospitalLocationUserInterface) {
    return row?.client_activated_time_local;
  }

  getRegStatusTooltip(status: string ): string {
    switch (status.trim().toLowerCase()) {
      case 'not_started':
        return 'The form was sent to the patient but has not been opened.';
      case 'in_progress':
        return 'The form was opened but not submitted.';
      case 'complete':
        return 'Form completed.';
      case 'healent-path-3630':
        return 'Patient has multiple appointments today.'
      case 'healent-path-3629':
        return 'Patient signature is missing on one or more required documents.'
      case 'healent-path-3631':
        return 'Patient missed some sections.'
      case 'healent-icon-5':
        return "It's the patient's birthday today."
      case 'healent-path-3625':
        return "Show the patient's contact information."
      case 'healent-path-3626':
        return 'Message the patient.'
      case 'healent-path-3627':
        return 'Visit and patient notes.'
      case 'healent-action-icon-1':
        return 'Payment details.'
      case 'healent-chart-check-action-1':
        return 'Payment details.'
      case 'healent-action-icon':
        return 'Manage visit.'
      case 'healent-bell':
        return 'Check alerts.';
      case 'healent-path-3633':
        return 'This appointment was manually checked-in'
      default:
        return 'The form could not be sent to the patient.';
    }

  }

  collectedAmount: number = 665.15;
  balancesDue: number = 17.14;
  totalAmount: number = this.collectedAmount + this.balancesDue;
  card: number = 426.2;
  cash: number = 92.4;
  check: number = 30.0;
  totalAmountTypes = this.cash + this.cash + this.check
  tooltipVisible: string | null = null;
  actionsList: string[] = ['healent-chart-check-action-1', 'healent-path-3625', 'healent-path-3626', 'healent-path-3627', 'healent-action-icon-1', 'healent-action-icon']
  //'healent-action-icon-1' add this icon name in the abvoe actions list when payment details is required

  // Collections percent
  collectedPercentage: number = (this.collectedAmount / this.totalAmount) * 100;
  balanceDuePercentage: number = (this.balancesDue / this.totalAmount) * 100;

  //payment method percent
  cardPercentage: number = (this.card / this.totalAmountTypes) * 100;
  cashPercentage: number = (this.cash / this.totalAmountTypes) * 100;
  checkPercentage: number = (this.check / this.totalAmountTypes) * 100;



  showTooltip(type: string) {
    this.tooltipVisible = type;
  }

  hideTooltip() {
    this.tooltipVisible = null;
  }

  //method to initialize icon and tooltip based on readiness status
  private setIconAndTooltip(row: any) {
    const readinessStatus = row.alerts.readiness_status;

    if (readinessStatus === 'Pending Check') {
      this.iconState[row.meeting_id] = 'healent-pending';
      this.tooltipState[row.meeting_id] = 'Pending Check';
    } else if (readinessStatus === 'Checking..') {
      this.iconState[row.meeting_id] = 'healent-chart-check-action';
      this.tooltipState[row.meeting_id] = 'Checking..';
    } else {
      this.iconState[row.meeting_id] = 'healent-chart-check-action-1';
      this.tooltipState[row.meeting_id] = 'Checked';
    }
  }

  getIconState(row: any): string {
    if (!this.iconState[row.meeting_id]) {
      this.setIconAndTooltip(row);
    }
    return this.iconState[row.meeting_id];
  }

  getTooltipState(row: any): string {
    if (!this.tooltipState[row.meeting_id]) {
      this.setIconAndTooltip(row);
    }
    return this.tooltipState[row.meeting_id];
  }

  toggleIconState(row: any) {
    const currentIcon = this.iconState[row.meeting_id];

    let updatedStatus = '';
    if (currentIcon === 'healent-chart-check-action-1') {
      updatedStatus = 'Pending Check';
    } else if (currentIcon === 'healent-pending') {
      updatedStatus = 'Checking..';
    } else {
      updatedStatus = 'Checked';
    }

    // Update readiness status via service and handle response
    this.registrationService.actionIconPatch(
      `registration/readiness/status/${row.meeting_id}`,
      { readiness_status: updatedStatus }
    ).subscribe((res) => {
      if (res.status === 200) {
        this.updateIconAndTooltip(row);
      }
    });
  }

  // Helper method to toggle icon and tooltip in toggleIconState
  private updateIconAndTooltip(row: any) {
    const currentIcon = this.iconState[row.meeting_id];

    if (currentIcon === 'healent-chart-check-action-1') {
      this.iconState[row.meeting_id] = 'healent-pending';
      this.tooltipState[row.meeting_id] = 'Pending Check';
    } else if (currentIcon === 'healent-pending') {
      this.iconState[row.meeting_id] = 'healent-chart-check-action';
      this.tooltipState[row.meeting_id] = 'Checking..';
    } else {
      this.iconState[row.meeting_id] = 'healent-chart-check-action-1';
      this.tooltipState[row.meeting_id] = 'Checked';
    }
  }


  onActionsClick (event: MouseEvent, action: string, row: HospitalLocationUserInterface, tableType: 'pre-registered' | 'in-progress' | 'checked-in') {
    event.stopPropagation();
    this.tableType = tableType;
    const allAppointments = this.userData$.getValue().filter(item => item.client_id === row.client_id).sort((a, b) => a.appointment_unixts - b.appointment_unixts);;
    // Create an array of newRow objects for each appointment
    const appointmentRows = allAppointments.map(appointment => ({
      name: appointment.client_name,
      chartNumber: appointment.chart_number,
      meetingId: appointment.meeting_id,
      provider: appointment.surgeon_name,
      apptType: appointment.appointment_type,
      apptTime: appointment.appointment_time,
      contactDetails: appointment.contact_details,
      patientNotes: appointment.alerts.patient_notes || {},
      visitNotes: appointment.alerts.meeting_notes || {},
      patientId: appointment.client_id,
      athenaNotes: appointment.alerts.athena_notes,
      payment_details: appointment.payment_details,
      alerts: appointment.alerts
    }));
    this.selectedRow = appointmentRows;
    if (action === 'healent-path-3626') {
      this.onBroadCastClick(event, tableType, 'action', row)
    } else {
      this.actionsDrawer.openDrawer();
      this.selectedAction = action;
      this.missed_sections = row.alerts?.form_sections_incomplete?.incomplete_sections || [];
      this.rawRow = row;
    }
  }

  onBroadCastClick(event: MouseEvent, source: 'pre-registered' | 'in-progress' | 'checked-in', action: string, row?: HospitalLocationUserInterface) {
    event.stopPropagation();

    const selectionMap = {
      'pre-registered': this.selection_pre_registered,
      'in-progress': this.selection_in_progress,
      'checked-in': this.selection_checked_in,
    };

    let selectedRows: HospitalLocationUserInterface[] = [];
    const selectedSource = selectionMap[source];

    if (row) {
      selectedRows = [row];
    } else if (selectedSource?.selected?.length) {
      selectedRows = [...selectedSource.selected];
    }

    this.actionsDrawer.openDrawer();
    this.selectedAction = 'broadcast';
    this.selectedRecords = selectedRows;
    this.title = action === 'panel-action' ? { id: 'bulk-message', name: 'Bulk Message' } : { id: 'message', name: 'Broadcast Message' }
  }

  updateTableAfterCancel(data: number): any[] | null {
    switch (this.tableType) {
      case 'pre-registered':
        this.pre_registered_data = this.pre_registered_data.filter((row) => row.meeting_id !== data);
        return this.pre_registered_data;
      case 'in-progress':
        this.registration_in_progress_data = this.registration_in_progress_data.filter((row) => row.meeting_id !== data);
        return this.registration_in_progress_data;
      case 'checked-in':
        this.checked_id_data = this.checked_id_data.filter((row) => row.meeting_id !== data);
        return this.checked_id_data;
      default:
        return null;
    }
  }

  cancelAppointment(meetingId: number) {
    this.registrationService.cancelApptPatch(`registration/cancel/${meetingId}`, {}).subscribe((res) => {
      if (res.status === 200) {
        const updatedData = this.updateTableAfterCancel(meetingId);
        if (updatedData) {
          this.dataSourcePreRegistered = new MatTableDataSource(updatedData);
          this.actionsDrawer.closeDrawer();
        }
      }
    }, (error) => {
      console.error(error);
    })
  }

  triggerDataRefresh(data: any) {
    if (data) {
      this.refreshFunctionTriggered = true;
      this.getData().subscribe((response) => {
        this.updateTableData(response)
      });
    }
  }

  // applyFilters(filters: any) {
  //   console.log(filters,'i am here first')
  //   this.fiterBarFilters = filters.length > 0 ? JSON.stringify(filters) : ''
  //   console.log(this.fiterBarFilters, '=======++++======')
  //   this.dataSourcePreRegistered.filter = this.fiterBarFilters;
  //   this.dataSourceInProgress.filter = this.fiterBarFilters;
  //   this.dataSourceCheckedIn.filter = this.fiterBarFilters;
  // }

  applyFilters(filters: [string, string][]) {
    // Only create filter string if there are filters
    this.fiterBarFilters = filters.length > 0 ? JSON.stringify(filters) : '';

    if (filters.length === 0) {
      this.selection_pre_registered.clear();
      this.selection_in_progress.clear();
      this.selection_checked_in.clear();
    }

    // Apply filters to all data sources
    [this.dataSourcePreRegistered, this.dataSourceInProgress, this.dataSourceCheckedIn].forEach(dataSource => {
      if (dataSource) {
        // Reset masks before applying new filters
        dataSource.data.forEach(row => row.mask = 1);
        dataSource.filterPredicate = this.createFilterPredicate();
        dataSource.filter = this.fiterBarFilters;
      }
    });
  }

  applySavedFilter(filterSelections: any) {
    this.applyFilters(filterSelections);
  }

  private createFilterPredicate(): (data: HospitalLocationUserInterface, filter: string) => boolean {
    return (data: HospitalLocationUserInterface, filter: string): boolean => {
      if (!filter) {
        data.mask = 1;
        return true;
      }

      let filterArray: [string, string][] = [];
      let searchText = '';

      try {
        const parsedFilter = JSON.parse(filter);
        // Check if it's a combined filter (both search and filters)
        if (typeof parsedFilter === 'object' && parsedFilter !== null) {
          if (parsedFilter.filters && parsedFilter.searchText) {
            filterArray = parsedFilter.filters;
            searchText = parsedFilter.searchText.toLowerCase();
          } else if (Array.isArray(parsedFilter)) {
            filterArray = parsedFilter;
          }
        } else {
          searchText = filter.toLowerCase();
        }
      } catch (e) {
        searchText = filter.toLowerCase();
      }

      // Reset mask
      data.mask = 1;

      // Group filters by category
      const filtersByCategory = filterArray.reduce((acc, [category, value]) => {
        if (!acc[category]) {
          acc[category] = [];
        }
        acc[category].push(value);
        return acc;
      }, {} as { [key: string]: string[] });

      // Apply filters for each category
      if (Object.keys(filtersByCategory).length > 0) {
        // Check each category separately
        for (const [category, values] of Object.entries(filtersByCategory)) {
          let categoryMatch = false;

          // For each value in the category, check if any match (OR condition)
          for (const value of values) {
            switch (category) {
              case 'alerts':
                if (data.alerts?.form_status.toLowerCase() === value) {
                  categoryMatch = true;
                }
                break;
              case 'surgeon_name':
                if (data.surgeon_name === value) {
                  categoryMatch = true;
                }
                break;
              case 'appointment_type':
                if (data.appointment_type === value) {
                  categoryMatch = true;
                }
                break;
              case 'insurance_name':
                if (data.insurance_name === value) {
                  categoryMatch = true;
                }
                break;
              case 'status':
                if (data.alerts?.form_status === value) {
                  categoryMatch = true;
                }
                break;
              default:
                categoryMatch = true;
            }

            if (categoryMatch) break;
          }

          // If none of the values in this category matched, exclude the row
          if (!categoryMatch) {
            data.mask = 0;
            break; // No need to check other categories if one fails
          }
        }
      }

      // If search text is present, search across all relevant fields
      if (searchText && data.mask === 1) {
        // Convert all data fields to string and search
        const searchableText = Object.entries(data)
          .filter(([key, value]) => {
            return value !== null &&
                   value !== undefined &&
                   typeof value !== 'object';
          })
          .map(([_, value]) => String(value).toLowerCase())
          .join(' ');

        if (!searchableText.includes(searchText)) {
          data.mask = 0;
        }
      }

      return data.mask === 1;
    };
  }

  markedRowsMap = new Map<number, boolean>();

  markRowEvent(input: { tableType: string; row: any; mark: boolean }) {
    const { tableType, row, mark } = input;

    // Persist marked state using meetingId
    this.markedRowsMap.set(row.meetingId, mark);

    // Update the specific row's "marked" state in the correct table data source
    let dataSource = [];
    switch (tableType) {
      case 'pre-registered':
        dataSource = this.pre_registered_data;
        break;
      case 'in-progress':
        dataSource = this.registration_in_progress_data;
        break;
      case 'checked-in':
        dataSource = this.checked_id_data;
        break;
      default:
        return;
    }

    // Find the row in the data source and update its "marked" property
    const rowToUpdate = dataSource.find((r) => r.meeting_id === row.meetingId);
    if (rowToUpdate) {
      rowToUpdate.marked = mark; // Add 'marked' property dynamically
    }
  }


} // end RegistrationDashboardComponent
