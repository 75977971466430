<div>
  <div class="haeder-patient-info">
    <div class="patient-info-header-right">
      <div class="header-description">
        Default Hospital - Patient Name
      </div>
      <div class="header-buttons">
        <button
          *ngFor="let button of buttons"
          class="header-button"
          [class.selected]="selectedSection === button.key"
          (click)="navigateTo(button.key)"
        >
          {{ button.label }}
        </button>
      </div>
    </div>
    <i [ngClass]="'healent-logout'" style="color: var(--secondary_shade_50); cursor: pointer; display: flex; align-items: center;" (click)="triggerCloseDrawer()"></i>
  </div>
  <div class="body-patient-info">
    <div class="appointment-table-info">
      <div class="appointment-header">
        <span style="font-size: 24px; font-weight: bold; color: var(--primary);">Appointments</span>
        <div class="appointment-icons">
          <i [ngClass]="'healent-ref'"></i>
          <i [ngClass]="'healent-document'"></i>
          <i [ngClass]="'healent-form-input-icon-8'"></i>
        </div>
      </div>
      <div class="appointment-table">
        <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="appointments-table">
            <!-- Table Columns -->
            <ng-container matColumnDef="type">
              <th mat-header-cell *matHeaderCellDef>Type</th>
              <td mat-cell *matCellDef="let row">
                <i [ngClass]="'healent-icon-2'" style="padding: 10px;"></i>
              </td>
            </ng-container>
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>Date</th>
              <td mat-cell *matCellDef="let row">{{ row.date }}</td>
            </ng-container>
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef>Time</th>
              <td mat-cell *matCellDef="let row">{{ row.time }}</td>
            </ng-container>
            <ng-container matColumnDef="timeSpent">
              <th mat-header-cell *matHeaderCellDef>Time Spent</th>
              <td mat-cell *matCellDef="let row">{{ row.timeSpent }}</td>
            </ng-container>
            <ng-container matColumnDef="completed">
              <th mat-header-cell *matHeaderCellDef>Completed</th>
              <td mat-cell *matCellDef="let row">
                <i [ngClass]="'healent-path-3624'" style="padding: 30px;"></i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <mat-paginator
          [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions"
          showFirstLastButtons
          class="fixed-paginator"
        ></mat-paginator>
      </div>
    </div>
    <div class="appointment-actions">
      <div class="actions-header">
        Patient Info
      </div>
      <div class="actions-content">
        <app-patient-info-component [patientData]="patientInfoData"></app-patient-info-component>
      </div>
    </div>
  </div>
</div>
