import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from "./environments/environment";

// Disable console.log in production
if (environment.production) {
  window.console.log = () => { };
}

// Define titles for different slugs
const titles: Record<string, string> = {
  'akps': 'AK Pain & Spine',
  'cps': 'Commonwealth Pain & Spine',
};

const startTime = performance.now();

// Function to set the favicon and title dynamically
function setFavIconAndTitle(slug: string): void {
  const iconElement = document.querySelector('link[rel="icon"]');
  if (iconElement) {
    iconElement.setAttribute('href', `${slug}-fav.ico`);
  } else {
    const newIcon = document.createElement('link');
    newIcon.setAttribute('rel', 'icon');
    newIcon.setAttribute('href', `${slug}-fav.ico`);
    document.head.appendChild(newIcon);
  }

  if (titles[slug]) {
    document.title = titles[slug];
  } else {
    console.error(`Title not found for slug: ${slug}`);
  }

  document.body.classList.add(slug);
}

// Get the slug from the environment
const slug = environment.slug;
if (slug) {
  setFavIconAndTitle(slug);
} else {
  console.error('No slug found in the environment');
}

// Bootstrap the Angular application
bootstrapApplication(AppComponent, appConfig)
.then(() => {
  const endTime = performance.now();
  const initializationTime = endTime - startTime;
  console.log(`Angular initialization time: ${initializationTime}ms`);
})
.catch((err) => console.error(err));
