import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { PatientInfoComponentComponent } from "./components/patient-info-component/patient-info-component.component";
import { TableActionsDrawerService } from '../../table-actions-drawer.service';
import moment from 'moment';

interface Appointment {
  id: number;
  type: string;
  date: string;
  time: string;
  timeSpent: string;
  completed: boolean;
}

@Component({
  selector: 'app-patient-info',
  standalone: true,
  imports: [CommonModule, MatTableModule, MatPaginatorModule, MatSortModule, PatientInfoComponentComponent],
  templateUrl: './patient-info.component.html',
  styleUrl: './patient-info.component.css'
})

export class PatientInfoComponent implements OnChanges {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @Output() closeDrawerEvent = new EventEmitter();
  @Input({required:true}) patientId!: string | number;

  buttons = [
    // { key: 'Monitoring', label: 'Monitoring' },
    // { key: 'Planning', label: 'Planning' },
    // { key: 'Diet', label: 'Diet' },
    // { key: 'Messages', label: 'Messages' },
    { key: 'PatientInfo', label: 'Patient Info' },
  ];
  selectedSection = 'PatientInfo';

  columns = [
    { name: 'type', header: 'Type' },
    { name: 'date', header: 'Date' },
    { name: 'time', header: 'Time' },
    { name: 'timeSpent', header: 'Time Spent' },
    { name: 'completed', header: 'Completed' }
  ]
  pageSize = 5
  pageSizeOptions = [ 10, 25, 50, 100, 250]
  displayedColumns = this.columns.map(col => col.name);
  dataSource: MatTableDataSource<Appointment>;
  // patientData = {
  //   name: 'Jessica Casta',
  //   lastUpdate: '2021-08-03 18:12:58',
  //   dateOfBirth: '1995-07-21',
  //   bmi: '40.7',
  //   phoneNo: '+1920216245',
  //   weight: '300 lbs',
  //   email: 'example@email.com',
  //   height: '72 inches',
  //   a1c: '--',
  //   surgeryDate: '2021-07-21 19:45:15',
  //   medicalRecordNumber: '4564156',
  //   careProtocol: 'Pain Management',
  //   dateSeenForOptimization: '2021-07-21 19:45:15',
  //   dateForReturnVisit: '2021-07-21 19:45:15',
  //   insuranceInfo: 'Massa Ultricies Mi Quis Hendrerit Dolor Magna Eget Est.',
  //   diagnosis: 'Lectus Proin Nibh Nisi Condimentum Id Venenatis. Ac Tortor Vitae Purus Faucibus.',
  //   outstandingIssues: 'Sed Do Eiusmod Tempor Incididunt Ut Labore Et Dolore Magna Aliqua.',
  //   totalAppointments: 18,
  //   firstAppointmentAt: '2021-07-21 19:45:15',
  //   lastAppointmentAt: '2021-07-21 19:45:15'
  // };
  patientInfoData: any;

  constructor( private tableActionsService: TableActionsDrawerService) {
    this.dataSource = new MatTableDataSource<Appointment>();
  }

  ngOnChanges (changes: SimpleChanges): void {
    console.log(' iwas hereee====')
    if (changes['patientId'] && this.patientId) {
      console.log(this.patientId, '===djusdjsjsd=====')
      this.tableActionsService.getAppointmentInfo({ client_id: this.patientId }).subscribe((res: any) => {
        if (res && res.appointments) {
          // Transform the API response into the format needed for the table
          const transformedAppointments = res.appointments.map((appointment: any) => ({
            id: appointment.meeting_id,
            type: appointment.appointment_type,
            date: this.formatDate(appointment.start_datetime_local), // Keep it as is or format it
            time: this.formatTime(appointment.start_datetime_local), // Extract and format time
            timeSpent: `${appointment.planned_duration} mins`,
            completed: appointment.duration_minutes > 0 // Mark as completed if duration > 0
          }));

          // Update the dataSource with the transformed data
          this.dataSource.data = transformedAppointments;
        }
      });
      this.tableActionsService.getPatientInfo({}, 19103).subscribe((res) => {
        this.patientInfoData = res.message
      })
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  private formatTime(dateTime: string): string {
    const momentDate = moment(dateTime, 'YYYY-MM-DD hh:mm A');
    return momentDate.format('hh:mm A'); // Format the time as needed
  }

  private formatDate(dateTime: string): string {
    const momentDate = moment(dateTime, 'YYYY-MM-DD hh:mm A');
    return momentDate.format('MMM DD, YYYY'); // Formats date as Apr 18, 1975
  }

  navigateTo(section: string) {
    this.selectedSection = section;
    console.log(`Navigating to ${section}`);
    // Add your navigation logic here
  }

  triggerCloseDrawer() {
    this.closeDrawerEvent.emit();
  }
}
