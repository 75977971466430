<div class="push-form-container">
  <div class="push-form-header">
    <div class="push-form-sub-header">
      <i [ngClass]="'healent-path-3656'"></i>
      <h2>Push form to the app</h2>
    </div>
    <!-- <div *ngIf="selectedItems.length === 0">
      <p>Hi, I prepared what should be pushed. Click below to add them directly!</p>
    </div>
    <div *ngIf="selectedItems.length > 0">
      <p>Great! I added them for you.</p>
    </div> -->
  </div>

  @if(notes.length > 0) {
  <div class="emr-notes">
    <div class="notes-header">
      <h2>Appointment note - Select what to include in the procedure consents</h2>
    </div>

    <div class="appointment-notes">
      <div class="text-container">
        <div class="scrollable-content">
          <mat-list>
            <mat-list-item *ngFor="let note of notes">
              <span class="emr-text" [innerHTML]="note"></span>
              <div class="dotted-line"></div>
            </mat-list-item>
          </mat-list>
        </div>
      </div>

      <div class="button-container">
        <button (click)="addSelectedText($event, 'sedation')" class="action-button sedation">
          Sedation
        </button>
        <button (click)="addSelectedText($event, 'nosedation')" class="action-button no-sedation">
          No Sedation
        </button>
      </div>
    </div>
  </div>
  }

  <!-- Action Buttons -->
  <!-- @if(actionButtons.length > 0) {
    <div class="action-buttons">
      <ng-container *ngFor="let button of actionButtons">
        <button [ngClass]="button.className" (click)="addToPushList(button, $event)" *ngIf="!button.added">
          {{ button.label }}
        </button>
      </ng-container>
    </div>
  } -->

  <!-- Form Selection Table -->
  <div class="form-selection">
    <!-- Search Column -->
    <div class="form-column search-column">
      <div style="border-bottom: 1px solid black; height: 50px;">
        <input class="search-input" type="text" placeholder="Search what to push" [(ngModel)]="searchTerm" />
      </div>
      <div class="selection-container">
        <div *ngFor="let item of filterItems(searchTerm)" class="selection-item">
          <mat-checkbox
            [checked]="isChecked(item)"
            (change)="toggleItem(item);">
            {{ item.form_name }}
          </mat-checkbox>
          <div *ngIf="item.lastModified" class="last-modified">
            (<span [ngClass]="getStatusColorClass(item.currentStatus || '')">
              {{this.getCurrentStatus(item.currentStatus || '')}}
            </span>
            <span>
              {{ item.lastModified }}
            </span>
            )
          </div>
        </div>
      </div>
    </div>

    <!-- Selected Items Column -->
    <div class="form-column selected-column">
      <div class="selected-column-text">To be pushed ({{ selectedItems.length }} items)</div>
      <div class="selection-container">
        <div *ngFor="let item of selectedItems" class="selection-item">
          <mat-checkbox
            [checked]="true"
            (click)="$event.stopPropagation(); removeItem(item)">
            {{ item.formatted_form_name }}
          </mat-checkbox>
        </div>
      </div>
    </div>
  </div>

  <!-- Push Button -->
  <div class="push-button">
    <button (click)="pushItems($event)" [disabled]="!(selectedItems.length > 0)">
      <div class="button-inner-container">
        <i [ngClass]="'healent-path-3656'"></i>
        <span style="margin-left: 10px;">Send</span>
      </div>
    </button>
  </div>
</div>
