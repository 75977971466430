import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

interface Patient {
  activated_at: string | null;
  assigned_nurse_id: number | null;
  assigned_nurse_name: string | null;
  billing_physician_id: number;
  billing_physician_name: string;
  bmi: number | null;
  client_id: number;
  client_name: string | null;
  client_timezone: string;
  date_of_birth: string;
  deactivated_at: string | null;
  email: string;
  gender: "Male" | "Female" | "Other";
  height: number | null;
  hospital_id: number;
  hospital_name: string;
  location_id: number;
  location_name: string;
  mobile_type: string | null;
  monitoring_program: string | null;
  mrn: string;
  name: string;
  pending_activation: 0 | 1;
  phone: string;
  plan_end_date: string;
  plan_start_date: string;
  primary_device: string | null;
  surgeon_id: number;
  surgeon_name: string;
  weight: number | null;
  insuranceInfo: string;
  diagnosis: string;
  outstandingIssues: string;
};

@Component({
  selector: 'app-patient-info-component',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './patient-info-component.component.html',
  styleUrl: './patient-info-component.component.css'
})
export class PatientInfoComponentComponent {
  @Input() patientData!: Patient;
}
